import styled from "styled-components";
import { themeTypes } from "../../../interfaces";
import { borderDisabled, fail } from "../../../theme";
import FloatingLabel from "react-bootstrap/FloatingLabel";

export const BaseInput = styled(FloatingLabel)<{
  haveError: boolean;
  theme: themeTypes;
}>`
  position: relative;
  width: 100%;
  min-width: 250px;
  flex: 1;

  input {
    transition: 0s all;
    border: 1px solid
      ${(props) =>
        props.haveError
          ? fail
          : props.theme === "light"
          ? "#8D8D8D"
          : "rgb(68 68 68)"} !important;

    :disabled {
      border: 1px solid ${(props) => borderDisabled[props.theme as themeTypes]} !important;
      color: #999999;
    }

    :enabled {
      background-color: ${(props) =>
        props.haveError ? "#FFF7F7" : "#FFF"} !important;
    }

    :focus {
      box-shadow: none !important;
      border: 1px solid #292929 !important;
      outline: 1px solid #292929 !important;
      background-color: #fff !important;
    }
  }

  label::after {
    background-color: transparent !important;
  }

  input:not(:focus) + label {
    color: ${(props) => (props.haveError ? fail : "##8D8D8D")} !important;
  }
`;

export const ContainerIconSeePassword = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;
