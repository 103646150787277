import React from "react";
import "./styles/scrollbar.css";
import "./styles/form.css";
import ReactDOM from "react-dom";
import "./styles/iconsmind/iconsmind.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-credit-cards/es/styles-compiled.css";
import { App } from "./App";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
