import NO_PASSWORD from "../../../../assets/NO_PASSWORD.svg";
import ONLINE_PASSWORD from "../../../../assets/ONLINE_PASSWORD.svg";
import OFFLINE_PASSWORD from "../../../../assets/OFFLINE_PASSWORD.svg";
import { IReservation } from "../../interfaces";

interface IFinish {
  offline_password?: string | null;
  online_password?: string | null;
  reservation: IReservation;
}

export const Finish: React.FC<IFinish> = ({
  offline_password,
  online_password,
  reservation,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "0 20px",
        flex: 1,
        alignItems: "center",
        gap: 20,
        paddingTop: 20,
      }}
    >
      <div
        style={{
          fontSize: 22,
          fontWeight: "bold",
          textAlign: "center",
          maxWidth: 220,
        }}
      >
        Check-in realizado com sucesso!
      </div>
      {!offline_password && !online_password && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 20,
          }}
        >
          <div>
            <img src={NO_PASSWORD} />
          </div>
          <div
            style={{
              textAlign: "center",
              color: "#5B5B5B",
              fontSize: 14,
              maxWidth: 200,
            }}
          >
            Em breve nosso time entrará em contato para combinar a entrega das
            chaves de sua acomodação.
          </div>
        </div>
      )}
      {offline_password && !online_password && (
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            alignItems: "center",
            gap: 20,
          }}
        >
          <div>
            <img src={OFFLINE_PASSWORD} />
          </div>
          <div style={{ color: "#5B5B5B" }}>
            Sua senha de acesso à acomodação reservada é:
          </div>
          <div
            style={{
              color: "#3A3A3A",
              fontWeight: "bold",
              fontSize: 18,
              padding: 10,
              borderRadius: 8,
              border: "2px dashed #E5E5E5",
            }}
          >
            {offline_password}
          </div>
          <div style={{ textAlign: "center", color: "#5B5B5B", fontSize: 14 }}>
            Essa senha é intransferível e só deve ser utilizada pelo titular da
            reserva e seus acompanhantes. Em breve nosso time entrará em contato
            para enviar mais detalhes.
          </div>
        </div>
      )}
      {!offline_password && online_password && (
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            alignItems: "center",
            gap: 20,
          }}
        >
          <div>
            <img src={ONLINE_PASSWORD} />
          </div>
          <div style={{ color: "#5B5B5B" }}>
            Sua senha de acesso à acomodação reservada é:
          </div>
          <div
            style={{
              color: "#3A3A3A",
              fontWeight: "bold",
              fontSize: 18,
              padding: 10,
              borderRadius: 8,
              border: "2px dashed #E5E5E5",
            }}
          >
            {online_password}
          </div>
          <div style={{ textAlign: "center", color: "#5B5B5B" }}>
            Essa senha foi gerada especificamente para sua reserva, a mesma
            estará disponível para utilização a partir do dia{" "}
            <b style={{ color: "#5B5B5B" }}>
              {reservation.check_in_date_formated_numbers}
            </b>{" "}
            e deixará de funcionar dia{" "}
            <b style={{ color: "#5B5B5B" }}>
              {reservation.check_out_date_formated_numbers}
            </b>
            .
          </div>
          <div style={{ textAlign: "center", color: "#5B5B5B", fontSize: 14 }}>
            Essa senha é intransferível e só deve ser utilizada pelo titular da
            reserva e seus acompanhantes. Em breve nosso time entrará em contato
            para enviar mais detalhes.
          </div>
        </div>
      )}
      {offline_password && online_password && (
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            alignItems: "center",
            gap: 20,
          }}
        >
          <div>
            <img src={NO_PASSWORD} />
          </div>
          <div style={{ color: "#5B5B5B" }}>Senha para acessar a portaria:</div>
          <div
            style={{
              color: "#3A3A3A",
              fontWeight: "bold",
              fontSize: 18,
              padding: 10,
              borderRadius: 8,
              border: "2px dashed #E5E5E5",
            }}
          >
            {online_password}
          </div>
          <div style={{ color: "#5B5B5B" }}>
            Sua senha de acesso à acomodação reservada é:
          </div>
          <div
            style={{
              color: "#3A3A3A",
              fontWeight: "bold",
              fontSize: 18,
              padding: 10,
              borderRadius: 8,
              border: "2px dashed #E5E5E5",
            }}
          >
            {offline_password}
          </div>
          <div style={{ textAlign: "center", color: "#5B5B5B" }}>
            Essa senha foi gerada especificamente para sua reserva, a mesma
            estará disponível para utilização a partir do dia{" "}
            <b style={{ color: "#5B5B5B" }}>
              {reservation.check_in_date_formated_numbers}
            </b>{" "}
            e deixará de funcionar dia{" "}
            <b style={{ color: "#5B5B5B" }}>
              {reservation.check_out_date_formated_numbers}
            </b>
            .
          </div>
          <div style={{ textAlign: "center", color: "#5B5B5B", fontSize: 14 }}>
            Essa senha é intransferível e só deve ser utilizada pelo titular da
            reserva e seus acompanhantes. Em breve nosso time entrará em contato
            para enviar mais detalhes.
          </div>
        </div>
      )}
      <div style={{ fontWeight: "bold", marginBottom: 20 }}>
        Bem-vindo a we.housy!
      </div>
    </div>
  );
};
