import React from "react";
import { CheckBox } from "./style";
import { themeTypes } from "../../../interfaces";
import { inactiveItens, secondary } from "../../../theme";

interface IRadioButtonProps {
  checked: boolean;
  onClick: () => void;
  disabled?: boolean;
  theme: themeTypes;
  label?: any;
  type: "radio" | "checkbox";
  icon?: any;
  centerNoIcon?: boolean;
  labelStyle?: React.CSSProperties;
}

export const MagicButton: React.FC<IRadioButtonProps> = (props) => {
  const {
    checked,
    onClick,
    disabled,
    theme,
    label,
    type,
    icon,
    centerNoIcon = false,
    labelStyle = {},
  } = props;

  return (
    <div style={{ display: "flex", justifyContent: "flex-start" }}>
      <label
        className={type}
        style={{
          display: "flex",
          cursor: !disabled ? "pointer" : "default",
          alignItems: "center",
          margin: 0,
        }}
      >
        <CheckBox
          disabled={disabled}
          onClick={() => onClick()}
          type={type}
          checked={checked}
          style={{ display: "none" }}
        />
        {icon && (
          <div
            style={{
              display: "flex",
              marginRight: 15,
              justifyContent: "center",
              alignItems: "center",
              minWidth: 50,
              maxWidth: 50,
              minHeight: 50,
              maxHeight: 50,
              borderRadius: "50%",
              background: secondary[theme],
            }}
          >
            {icon}
          </div>
        )}
        {label && (
          <span
            style={{
              whiteSpace: "nowrap",
              fontSize: 12,
              color: inactiveItens[theme],
              marginLeft: -5,
              ...labelStyle,
            }}
          >
            {label}
          </span>
        )}
        {centerNoIcon && (
          <span
            style={{
              background: disabled
                ? checked
                  ? inactiveItens["light"]
                  : "#C4C4C4"
                : checked
                ? secondary[theme]
                : "#C4C4C4",
              height: 16,
              width: 16,
            }}
            className="checkmark"
          />
        )}
        {!centerNoIcon && (
          <span
            style={{
              background: disabled
                ? checked
                  ? inactiveItens["light"]
                  : "#C4C4C4"
                : checked
                ? secondary[theme]
                : "#C4C4C4",
              height: 16,
              width: 16,
              top: "50%",
              left: label ? undefined : "50%",
              transform: label ? "translateY(-50%)" : "translate(-50%, -50%)",
            }}
            className="checkmark"
          />
        )}
      </label>
    </div>
  );
};
