import PHOTO from "./../../../../../../../../../assets/PHOTO.svg";
import SELFIE from "./../../../../../../../../../assets/SELFIE.svg";
import Button from "../../../../../../../../../components/Buttons/Button";
import Webcam from "react-webcam";
import { BallonCard } from "./style";
import { CgArrowLeft } from "react-icons/cg";
import { useCallback, useEffect, useRef, useState } from "react";
import { inactiveItens } from "../../../../../../../../../theme";
import { useGlobal } from "../../../../../../../../../hooks/global";
import { MyCircularProgress } from "../../../../../../../../../components/MyCircularProgress";
import { PhoneIcon } from "../../../../../../../../../components/SvgComponents/Icons/PhoneIcon";
import {
  BadQuality,
  BadQuality2,
} from "../../../../../../../../../components/SvgComponents/Icons/BadQuality";
import { StartSelfie } from "../startSelfie";
import { GoodQuality } from "../../../../../../../../../components/SvgComponents/Icons/GoodQuality";
import { InstructionStep } from "../../../../../../../../../components/SvgComponents/Icons/InstructionStep";
import { useApi } from "../../../../../../../../../hooks/api";
import { GuestCheck2 } from "../../../../../../../../../components/SvgComponents/Icons/GuestCheck";
import { AiOutlineCloseCircle } from "react-icons/ai";
import {
  IInputDocumentRefProps,
  InputDocument,
} from "../../../../../../../../../components/Inputs/InputFile";
import { fileProps } from "../../../../../../../../../interfaces";
import { UploadIcon } from "../../../../../../../../../components/SvgComponents/Icons/UploadIcon";

export interface IStep {
  onNext: () => void;
  goBack: () => void;
}

const InstructionsStep = ({ goBack, onNext }: IStep) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "0 20px",
        flex: 1,
      }}
    >
      <div
        style={{ display: "flex", flexDirection: "column", gap: 10, flex: 1 }}
      >
        <div
          style={{
            display: "flex",
            gap: 10,
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: 18,
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              gap: 5,
              position: "relative",
              justifyContent: "center",
              flex: 1,
            }}
          >
            <div
              style={{ position: "absolute", left: 0 }}
              className="pointer"
              onClick={() => goBack()}
            >
              <CgArrowLeft color={inactiveItens.light} size={20} />
            </div>
            <div>Instruções de envio</div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ fontWeight: "bold" }}>Siga as orientações</div>
            <div style={{ fontSize: 14 }}>
              Isso pode facilitar a sua aprovação
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 20,
              marginTop: 40,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                alignItems: "center",
                minWidth: 250,
              }}
            >
              <InstructionStep />
              <div style={{ color: "#3A3A3A", fontSize: 14 }}>
                Tire o documento do plástico
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                alignItems: "center",
                minWidth: 250,
              }}
            >
              <InstructionStep />
              <div style={{ color: "#3A3A3A", fontSize: 14 }}>
                Coloque o documento na vertical
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                alignItems: "center",
                minWidth: 250,
              }}
            >
              <InstructionStep />
              <div style={{ color: "#3A3A3A", fontSize: 14 }}>
                Evite reflexos e sombras
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                alignItems: "center",
                minWidth: 250,
              }}
            >
              <InstructionStep />
              <div style={{ color: "#3A3A3A", fontSize: 14 }}>
                Verifique a qualidade da foto
              </div>
            </div>
          </div>
        </div>

        <Button
          background={"#6BFEB1"}
          color={"#292929"}
          style={{ marginTop: 30 }}
          onClick={onNext}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flex: 1,
              padding: "0px 10px",
            }}
          >
            Entendi
          </div>
        </Button>
      </div>
    </div>
  );
};

const InstructionsSelfieStep = ({ goBack, onNext }: IStep) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "0 20px",
        flex: 1,
      }}
    >
      <div
        style={{ display: "flex", flexDirection: "column", gap: 10, flex: 1 }}
      >
        <div
          style={{
            display: "flex",
            gap: 10,
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: 18,
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              gap: 5,
              position: "relative",
              justifyContent: "center",
              flex: 1,
            }}
          >
            <div
              style={{ position: "absolute", left: 0 }}
              className="pointer"
              onClick={() => goBack()}
            >
              <CgArrowLeft color={inactiveItens.light} size={20} />
            </div>
            <div>Instruções de envio</div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ fontWeight: "bold" }}>Siga as orientações</div>
            <div style={{ fontSize: 14 }}>
              Isso pode facilitar a sua aprovação
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 20,
              marginTop: 40,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                alignItems: "center",
                minWidth: 250,
              }}
            >
              <InstructionStep />
              <div style={{ color: "#3A3A3A", fontSize: 14 }}>
                Procure um local iluminado
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                alignItems: "center",
                minWidth: 250,
              }}
            >
              <InstructionStep />
              <div style={{ color: "#3A3A3A", fontSize: 14 }}>
                Retire boné e óculos de sol
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                alignItems: "center",
                minWidth: 250,
              }}
            >
              <InstructionStep />
              <div style={{ color: "#3A3A3A", fontSize: 14 }}>
                Siga as orientações de captura
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                alignItems: "center",
                minWidth: 250,
              }}
            >
              <InstructionStep />
              <div style={{ color: "#3A3A3A", fontSize: 14 }}>
                Verifique a qualidade da foto
              </div>
            </div>
          </div>
        </div>

        <Button
          background={"#6BFEB1"}
          color={"#292929"}
          style={{ marginTop: 30 }}
          onClick={onNext}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flex: 1,
              padding: "0px 10px",
            }}
          >
            Entendi
          </div>
        </Button>
      </div>
    </div>
  );
};

const TakeStep = ({
  goBack,
  onNext,
  type,
}: Pick<IStep, "goBack"> & {
  onNext: (photo: string) => void;
  type: "front" | "back";
}) => {
  const [loading, setLoading] = useState(false);
  const { api } = useApi();
  const [file, setFile] = useState<fileProps | null>(null);

  const inputFileRef = useRef<IInputDocumentRefProps>(null);

  const transformImageInBase64 = useCallback(async (image: any) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("photo", image.file);
      const { data } = await api.post("/photos/transform", formData);
      onNext(data.complete);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (file) transformImageInBase64(file);
  }, [file]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "0 20px",
        flex: 1,
      }}
    >
      <div
        style={{ display: "flex", flexDirection: "column", gap: 10, flex: 1 }}
      >
        <div
          style={{
            display: "flex",
            gap: 10,
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: 18,
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              gap: 5,
              position: "relative",
              justifyContent: "center",
              flex: 1,
            }}
          >
            <div
              style={{ position: "absolute", left: 0 }}
              className="pointer"
              onClick={() => goBack()}
            >
              <CgArrowLeft color={inactiveItens.light} size={20} />
            </div>
            <div>Foto {type === "front" ? "da frente" : "do verso"}</div>
          </div>
        </div>
        <div style={{ display: "flex", flex: 1 }}>
          <div style={{ display: "none" }}>
            <InputDocument
              ref={inputFileRef}
              onChange={(e) => setFile(e)}
              fileTypes={["jpeg", "jpg"]}
            />
          </div>
          <div
            onClick={() => (!loading ? inputFileRef.current?.focus() : 0)}
            style={{
              display: "flex",
              padding: 20,
              justifyContent: "center",
              alignItems: "center",
              border: "2px dashed #E5E5E5",
              borderRadius: 8,
              flexDirection: "column",
              flex: 1,
              cursor: !loading ? "pointer" : "default",
              marginBottom: 20,
            }}
          >
            {!loading && (
              <>
                <UploadIcon />
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: 18,
                    textAlign: "center",
                    maxWidth: 280,
                    marginTop: 20,
                  }}
                >
                  Clique aqui ou arraste um arquivo para carregar
                </div>
                <div style={{ fontSize: 14 }}>Formato JPG ou JPEG.</div>
              </>
            )}
            {loading && (
              <>
                <MyCircularProgress color="#00FE79" size={30} />
                <div style={{ marginTop: 20, fontWeight: "bold" }}>
                  Carregando documento
                </div>
                <div style={{ fontSize: 14 }}>Processando a imagem...</div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const TakeSelfieStep = ({
  goBack,
  onNext,
}: Pick<IStep, "goBack"> & {
  onNext: (photo: string) => void;
}) => {
  const { containerGlobalRef } = useGlobal();

  const webcamRef = useRef<Webcam>(null);

  const capture = useCallback(() => {
    const imageSrc = webcamRef?.current?.getScreenshot();
    if (!imageSrc) return;
    onNext(imageSrc);
  }, [webcamRef]);

  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        minHeight: containerGlobalRef.current?.clientHeight,
        background: "#3D3D3D",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 30,
      }}
    >
      <div
        onClick={goBack}
        style={{
          cursor: "pointer",
          padding: 10,
          position: "absolute",
          right: 0,
          top: 0,
        }}
      >
        <AiOutlineCloseCircle fill="#f7f7f7" size={20} />
      </div>
      <BallonCard>
        <div style={{ fontWeight: "bold", fontSize: 18 }}>
          Alinhe o rosto no círculo
        </div>
        <div style={{ fontSize: 14 }}>
          Retire bonés, óculos ou outro acessório
        </div>
      </BallonCard>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            border: "2px dashed #00FE79",
            padding: 8,
            borderRadius: "50%",
          }}
        >
          <div
            style={{
              borderRadius: "50%",
              overflow: "hidden",
              height: (containerGlobalRef.current?.clientWidth ?? 0) * 0.5625,
              width: (containerGlobalRef.current?.clientWidth ?? 0) * 0.5625,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{}}>
              <Webcam
                style={{ height: "100%" }}
                ref={webcamRef}
                mirrored
                screenshotFormat="image/jpeg"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          padding: 16,
          borderRadius: "50%",
          background: "#6BFEB1",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={capture}
      >
        <PhoneIcon color="#000" />
      </div>
    </div>
  );
};

const ValidationImageStep = ({
  goBack,
  onNext,
  image,
  isValid = false,
}: IStep & { image: string; isValid?: boolean }) => {
  const [loading, setLoading] = useState(!isValid);
  const [valid, setValid] = useState(isValid);
  const { api } = useApi();

  const validateImage = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.post("/photos/validate", { photo: image });
      setValid(data.valid);
    } catch (e) {}
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!isValid) validateImage();
  }, [isValid]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "0 20px",
        flex: 1,
      }}
    >
      <div
        style={{ display: "flex", flexDirection: "column", gap: 10, flex: 1 }}
      >
        <div
          style={{
            display: "flex",
            gap: 10,
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: 18,
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              gap: 5,
              position: "relative",
              justifyContent: "center",
              flex: 1,
            }}
          >
            {!loading && (
              <div
                style={{ position: "absolute", left: 0 }}
                className="pointer"
                onClick={() => goBack()}
              >
                <CgArrowLeft color={inactiveItens.light} size={20} />
              </div>
            )}
            <div>Verificar documento</div>
          </div>
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", flex: 1, gap: 30 }}
        >
          <div
            style={{
              padding: 16,
              borderRadius: 8,
              background: loading ? "#EFEFEF" : valid ? "#E6FFF2" : "#FFF7F7",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              gap: 20,
            }}
          >
            {loading && (
              <>
                <MyCircularProgress size={25} color="#00FE79" />
                <div style={{ fontSize: 14 }}>
                  Checando a qualidade da imagem
                </div>
              </>
            )}
            {!loading && valid && (
              <>
                <GoodQuality />
                <div style={{ fontSize: 14 }}>
                  A imagem está com{" "}
                  <b style={{ fontWeight: "bold" }}>boa qualidade</b>
                </div>
              </>
            )}
            {!loading && !valid && (
              <>
                <BadQuality />
                <div style={{ fontSize: 14 }}>
                  A imagem esta{" "}
                  <b style={{ fontWeight: "bold" }}>sem qualidade</b>
                </div>
              </>
            )}
          </div>
          <div
            style={{
              padding: 30,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "#F7F7F7",
              borderRadius: 8,
            }}
          >
            <img style={{ width: "90%", borderRadius: 8 }} src={image} />
          </div>
        </div>
        <Button
          background={"#6BFEB1"}
          color={"#292929"}
          onClick={onNext}
          disabled={loading || !valid}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flex: 1,
              padding: "0px 10px",
            }}
          >
            Salvar e continuar
          </div>
        </Button>
      </div>
    </div>
  );
};

const PrepareSelfie = ({ goBack, onNext }: IStep) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "0 20px",
        flex: 1,
      }}
    >
      <div
        style={{ display: "flex", flexDirection: "column", gap: 10, flex: 1 }}
      >
        <div
          style={{
            display: "flex",
            gap: 10,
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: 18,
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              gap: 5,
              position: "relative",
              justifyContent: "center",
              flex: 1,
            }}
          >
            <div
              style={{ position: "absolute", left: 0 }}
              className="pointer"
              onClick={() => goBack()}
            >
              <CgArrowLeft color={inactiveItens.light} size={20} />
            </div>
            <div>Capture uma selfie</div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 20,
            flex: 1,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 5,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={SELFIE} />
            <div
              style={{
                fontSize: 18,
                fontWeight: "bold",
                maxWidth: 300,
                textAlign: "center",
                marginTop: 20,
              }}
            >
              Tire uma selfie
            </div>
            <div
              style={{
                fontSize: 14,
                color: "#5B5B5B",
                maxWidth: 250,
                textAlign: "center",
              }}
            >
              Precisamos de uma foto do seu rosto para cadastrar sua biometria.
            </div>
          </div>
        </div>
        <Button
          background={"#6BFEB1"}
          color={"#292929"}
          style={{ marginTop: 30 }}
          onClick={onNext}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flex: 1,
              padding: "0px 10px",
            }}
          >
            Iniciar captura
          </div>
        </Button>
      </div>
    </div>
  );
};

const MatchPhotos = ({
  onNext,
  isValid = false,
  image_front,
  image_selfie,
  onFail,
}: Pick<IStep, "onNext"> & {
  onFail: () => void;
  isValid?: boolean;
  image_front: string;
  image_selfie: string;
}) => {
  const [loading, setLoading] = useState(!isValid);
  const [valid, setValid] = useState(isValid);
  const { api } = useApi();

  const matchImages = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.post("/photos/compare", {
        photo_1: image_selfie,
        photo_2: image_front,
      });
      setValid(data.valid);
    } catch (e) {}
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!isValid) matchImages();
  }, [isValid]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "0 20px",
        flex: 1,
      }}
    >
      <div
        style={{ display: "flex", flexDirection: "column", gap: 10, flex: 1 }}
      >
        <div
          style={{
            display: "flex",
            gap: 10,
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: 18,
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              gap: 5,
              position: "relative",
              justifyContent: "center",
              flex: 1,
            }}
          >
            <div>Verificação da biometria</div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {loading && (
            <>
              <MyCircularProgress color="#00FE79" size={30} />
              <div style={{ fontWeight: "bold", marginTop: 20 }}>
                Verificação em andamento
              </div>
              <div style={{ fontSize: 14 }}>
                Estamos verificando a biometria fácil do hóspede
              </div>
            </>
          )}
          {!loading && valid && (
            <>
              <GuestCheck2 />
              <div style={{ fontWeight: "bold", marginTop: 20 }}>
                Biometria confirmada
              </div>
              <div style={{ fontSize: 14 }}>
                A biometria do hóspede foi confirmada com sucesso.
              </div>
            </>
          )}
          {!loading && !valid && (
            <>
              <BadQuality2 />
              <div style={{ fontWeight: "bold", marginTop: 20 }}>
                Biometria não confirmada
              </div>
              <div style={{ fontSize: 14 }}>
                A biometria do hóspede não foi confirmada.
              </div>
            </>
          )}
        </div>
        {!loading && (
          <Button
            background={"#6BFEB1"}
            color={"#292929"}
            onClick={() => (valid ? onNext() : onFail())}
            disabled={loading}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flex: 1,
                padding: "0px 10px",
              }}
            >
              {valid ? "Voltar para hóspedes" : "Tentar novamente"}
            </div>
          </Button>
        )}
      </div>
    </div>
  );
};

export const FileInstructionsFile: React.FC<
  Pick<IStep, "goBack"> & {
    document_type: "rg" | "passport" | "license";
    onNext: (data: {
      frontImage: string | null;
      backImage: string | null;
      selfieImage: string | null;
    }) => void;
  }
> = ({ goBack, onNext, document_type }) => {
  const [step, setStep] = useState<
    | "instructions"
    | "send-file-front"
    | "send-file-back"
    | "validation-front"
    | "validation-back"
    | "start-selfie"
    | "prepare-selfie"
    | "instructions-selfie"
    | "send-selfie"
    | "match-photos"
  >("instructions");
  const [frontImage, setFrontImage] = useState<string | null>(null);
  const [backImage, setBackImage] = useState<string | null>(null);
  const [frontIsValid, setFrontIsValid] = useState(false);
  const [backIsValid, setBackIsValid] = useState(false);
  const [selfieImage, setSelfieImage] = useState<string | null>(null);
  const [selfieIsValid, setSelfieIsValid] = useState(false);

  if (step === "instructions") {
    return (
      <InstructionsStep
        goBack={goBack}
        onNext={() => setStep("send-file-front")}
      />
    );
  }

  if (step === "send-file-front") {
    return (
      <TakeStep
        goBack={() => setStep("instructions")}
        onNext={(photo: string) => {
          setFrontImage(photo);
          setStep("validation-front");
        }}
        type="front"
      />
    );
  }

  if (step === "send-file-back") {
    return (
      <TakeStep
        goBack={() => setStep("validation-front")}
        onNext={(photo: string) => {
          setBackImage(photo);
          setStep("validation-back");
        }}
        type="back"
      />
    );
  }

  if (step === "validation-front") {
    return (
      <ValidationImageStep
        image={frontImage as any}
        goBack={() => setStep("send-file-front")}
        onNext={() => {
          setFrontIsValid(true);
          if (document_type !== "rg") setStep("start-selfie");
          else setStep("send-file-back");
        }}
        isValid={frontIsValid}
      />
    );
  }

  if (step === "validation-back") {
    return (
      <ValidationImageStep
        image={backImage as any}
        goBack={() => setStep("send-file-back")}
        onNext={() => {
          setBackIsValid(true);
          setStep("start-selfie");
        }}
        isValid={backIsValid}
      />
    );
  }

  if (step === "start-selfie") {
    return (
      <StartSelfie
        goBack={() =>
          setStep(
            document_type !== "rg" ? "validation-front" : "validation-back"
          )
        }
        onNext={() => setStep("prepare-selfie")}
      />
    );
  }

  if (step === "prepare-selfie") {
    return (
      <PrepareSelfie
        goBack={() => setStep("start-selfie")}
        onNext={() => setStep("instructions-selfie")}
      />
    );
  }

  if (step === "instructions-selfie") {
    return (
      <InstructionsSelfieStep
        goBack={() => setStep("prepare-selfie")}
        onNext={() => setStep("send-selfie")}
      />
    );
  }

  if (step === "send-selfie") {
    return (
      <TakeSelfieStep
        goBack={() => setStep("instructions-selfie")}
        onNext={(selfie) => {
          setSelfieImage(selfie);
          setStep("match-photos");
        }}
      />
    );
  }

  if (step === "match-photos") {
    return (
      <MatchPhotos
        onFail={() => setStep("send-selfie")}
        image_front={frontImage as any}
        image_selfie={selfieImage as any}
        onNext={() => onNext({ frontImage, backImage, selfieImage })}
      />
    );
  }

  return null;
};
