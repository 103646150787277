import * as React from "react";

export const GuestIcon = ({ size, color, ...props }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      stroke="#292929"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M19 18.3v-1.4a2.801 2.801 0 0 0-2.1-2.712m-2.45-8.285a2.801 2.801 0 0 1 0 5.193M15.5 18.3c0-1.305 0-1.957-.213-2.472a2.8 2.8 0 0 0-1.516-1.515c-.514-.213-1.166-.213-2.471-.213H9.2c-1.305 0-1.957 0-2.472.213a2.8 2.8 0 0 0-1.515 1.515C5 16.343 5 16.995 5 18.3m8.05-9.8a2.8 2.8 0 1 1-5.6 0 2.8 2.8 0 0 1 5.6 0Z"
    />
  </svg>
);
