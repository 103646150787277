import React, { useCallback } from "react";
import { Start } from "./steps/start";
import { Guest, IReservation } from "./interfaces";
import { Container, Content } from "./style";
import { useGlobal } from "../../hooks/global";
import { ReservationSteps } from "./steps/reservationSteps";
import { LogoIcon } from "../../components/SvgComponents/Icons/LogoIcon";
import { IconPowered } from "../../components/SvgComponents/Icons/IconPowered";
import { MyCircularProgress } from "../../components/MyCircularProgress";
import { useApi } from "../../hooks/api";
import { Finish } from "./steps/finish";
import { useSearchParams } from "react-router-dom";

const DEFAULT_RESERVATION = {
  id: "EO06I",
  check_in_date: "2024-02-18T00:00:00.000Z",
  check_out_date: "2024-02-23T00:00:00.000Z",
  check_in_time: "15:00",
  check_out_time: "12:00",
  payment_status: "paid",
  reservation_status: "booked",
  accommodation_stays_id: "BI02I",
  guests: [
    {
      primary: true,
      type: "adult",
      name: "Joabe Brasil",
      email: "joabe@gmail.com",
      phone: "77999880456",
      birth: "1989-02-24",
      cpf: "03319213598",
      rg: null,
    },
    {
      primary: false,
      name: "Erica Brasil",
      type: "adult",
      email: "erica@gmail.com",
      phone: "77999880456",
      birth: "1989-02-24",
      cpf: "73782457048",
      rg: null,
    },
  ],
  origin: "External API",
  total_guests: 3,
  createdAt: "2024-02-18T00:00:00.000Z",
  updatedAt: "2024-04-07T03:32:07.014Z",
  accommodation: {
    id: "BI02I",
    access_type: "normal",
    password: null,
    name: "AP119",
    address: {
      countryCode: "BR",
      state: "Bahia",
      stateCode: "BA",
      city: "Barreiras",
      region: "Parque das Águas",
      street: "Rua da Prainha",
      streetNumber: "SN",
      additional: "AP119",
      zip: "47810-047",
    },
    smartlock_alias: null,
    areas_groups_ids: null,
    main_image:
      "https://we-housy-images.s3.sa-east-1.amazonaws.com/00350761-a7d1-4635-9e59-caeb8145d3a6.jpeg",
    images: [
      "https://we-housy-images.s3.sa-east-1.amazonaws.com/4fb815c2-b3e8-49e0-8988-41c371dcffc7.jpeg",
      "https://we-housy-images.s3.sa-east-1.amazonaws.com/2257a3ec-943c-48ea-a149-72ece6284074.jpeg",
      "https://we-housy-images.s3.sa-east-1.amazonaws.com/af37f9bf-b936-406d-b1a1-993d5251b8e5.jpeg",
      "https://we-housy-images.s3.sa-east-1.amazonaws.com/33b8a8f6-4690-4743-b259-821d07d86715.jpeg",
      "https://we-housy-images.s3.sa-east-1.amazonaws.com/033cdd67-538f-4dc3-b95a-6d1b6a77ade6.jpeg",
      "https://we-housy-images.s3.sa-east-1.amazonaws.com/0d3611c6-d5d0-4820-8bac-23e1a65b4940.jpeg",
      "https://we-housy-images.s3.sa-east-1.amazonaws.com/030ddf2f-a352-4245-8417-bbed20465bfe.jpeg",
      "https://we-housy-images.s3.sa-east-1.amazonaws.com/11e985f5-e8a6-40a2-801a-b6317b15382a.jpeg",
      "https://we-housy-images.s3.sa-east-1.amazonaws.com/76a4e460-9e8f-4a9f-abdf-bada170dfe0c.jpeg",
      "https://we-housy-images.s3.sa-east-1.amazonaws.com/ceb594c0-b152-4edf-8544-0fc3daf0294c.jpeg",
    ],
    updatedImages: "2024-04-06T20:20:15.850Z",
    max_guests: 4,
    rooms: 2,
    beds: 2,
    bathrooms: 2,
    bedsDetails: [
      {
        _id: "5ab8f8a2f6b2dc2e97f97043",
        bed_type: "Cama (s) Queen",
        room_type: "Suíte",
        count: 1,
      },
      {
        _id: "5ab8f8a2f6b2dc2e97f97043",
        bed_type: "Cama (s) Queen",
        room_type: "Individual",
        count: 1,
      },
    ],
    building_stays_id: "6563e84666f207ad110da528",
    title: "Soft - Uma vida mais leve e do seu jeito",
    createdAt: "2024-04-06T17:46:39.488Z",
    updatedAt: "2024-04-07T03:33:03.887Z",
  },
};

const SECOND_DEFAULT_RESERVATION = {
  id: "GH01I",
  check_in_date: "2024-04-03T00:00:00.000Z",
  check_out_date: "2024-04-09T00:00:00.000Z",
  check_in_time: "15:00",
  check_out_time: "12:00",
  payment_status: "paid",
  reservation_status: "booked",
  accommodation_stays_id: "AY01I",
  guests: [
    {
      name: "Juarez Pinheiro",
      email: "joabebrasil@gmail.com",
      phone: "7736128555",
      birth: "1963-04-19",
      cpf: "03319213598",
      rg: null,
      type: "adult",
      primary: true,
    },
    {
      name: "Erics Brasil",
      email: "erica_brasil@cargill.com",
      cpf: "",
      rg: null,
      type: "adult",
      primary: false,
    },
  ],
  origin: "Website",
  total_guests: 2,
  createdAt: "2024-03-23T00:00:00.000Z",
  updatedAt: "2024-04-19T02:59:51.007Z",
  accommodation: {
    id: "AY01I",
    access_type: "off",
    password: "12345#",
    name: "AP 1002",
    address: {
      countryCode: "BR",
      state: "Bahia",
      stateCode: "BA",
      city: "Barreiras",
      region: "Renato Gonçalves",
      street: "Rua Doze de Outubro",
      streetNumber: "527",
      additional: "AP 1002",
      zip: "47804210",
    },
    smartlock_alias: null,
    areas_groups_ids: ["7894524397", "1235219325"],
    main_image:
      "https://we-housy-images.s3.sa-east-1.amazonaws.com/9f8d97da-da8a-4503-b6c0-5e6b4ecd148a.jpeg",
    images: [
      "https://we-housy-images.s3.sa-east-1.amazonaws.com/d5199a1b-41bc-4071-8276-3593a78acfd4.jpeg",
      "https://we-housy-images.s3.sa-east-1.amazonaws.com/4b78c688-6d96-4707-aa86-0ad77dd7596c.jpeg",
      "https://we-housy-images.s3.sa-east-1.amazonaws.com/0b0d6719-a334-4f0a-a6ee-67470ee6b6a4.jpeg",
      "https://we-housy-images.s3.sa-east-1.amazonaws.com/656e6675-300f-4c83-bda4-47eae87ed9cd.jpeg",
      "https://we-housy-images.s3.sa-east-1.amazonaws.com/2bf04bc9-c5e7-4288-93c5-292d9fd76fd0.jpeg",
    ],
    updatedImages: "2024-04-18T07:08:17.541Z",
    max_guests: 2,
    rooms: 1,
    beds: 2,
    bathrooms: 1,
    bedsDetails: [
      {
        _id: "5ab8f8a2f6b2dc2e97f97040",
        bed_type: "Cama (s) de Solteiro",
        room_type: "Estúdio",
        count: 2,
      },
    ],
    building_stays_id: "654b9931e142b1914ba90d2b",
    title:
      "City House Barreiras - Lindo e moderno no coração do bairro mais charmoso da cidade",
    createdAt: "2024-04-06T17:46:39.964Z",
    updatedAt: "2024-04-19T02:59:38.023Z",
  },
};

export const WebCheckIn: React.FC = () => {
  const [step, setStep] = React.useState<
    "start" | "reservation-steps" | "finish"
  >("start");

  const [params] = useSearchParams();

  const { api } = useApi();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [controlRender, setControlRender] = React.useState<boolean>(false);

  const { theme, containerGlobalRef, notify } = useGlobal();

  const [reservation, setReservation] = React.useState<IReservation | null>(
    null
  );

  const [passwords, setPasswords] = React.useState<{
    offline_password: string | null;
    online_password: string | null;
  }>({
    offline_password: null,

    online_password: null,
  });

  const handleSave = useCallback(
    async (guests: Guest[]) => {
      setLoading(true);
      try {
        const correct_guests = guests.map((guest) => {
          return {
            ...guest,
            birth: new Date(guest.birth + "T03:00:00.000Z"),
            document_type: guest.cpf ? "cpf" : "passport",
            document_value: guest.cpf ? guest.cpf : guest.passport,
            document_image_back: guest.document_file_back,
            document_image_front: guest.document_file,
            selfie_image: guest.selfie_file,
          };
        });
        const { data } = await api.post("/check-ins", {
          reservation_stays_id: reservation?.id,
          guests: correct_guests,
        });
        setPasswords(data);
        notify("Check-in realizado com sucesso!", "success");
        setStep("finish");
      } catch (e) {
        notify(
          "Problema ao realizar o check-in! Tente novamente mais tarde",
          "alert"
        );
      }
      setLoading(false);
    },
    [reservation]
  );

  const StartChildren = (
    <Start
      onSave={(reservation) => {
        const correctReservation = reservation as IReservation;
        correctReservation.guests = correctReservation.guests.sort((a, b) =>
          a.primary ? -1 : 1
        );
        setReservation(correctReservation);
        setStep("reservation-steps");
      }}
      defaultReservationId={params.get("reservation_id") ?? ""}
      defaultCheckInDate={params.get("date") ?? ""}
    />
  );

  return (
    <Container>
      <div style={{ marginTop: 30, marginBottom: 10 }}>
        <LogoIcon theme={theme} />
      </div>
      <Content
        ref={containerGlobalRef}
        style={{
          position: "relative",
          overflow: "hidden",
          display: loading ? "none" : "flex",
        }}
      >
        {step === "start" && !controlRender && StartChildren}
        {step === "start" && controlRender && StartChildren}
        {step === "reservation-steps" && (
          <ReservationSteps
            reservation={reservation as any}
            onSave={handleSave}
          />
        )}
        {step === "finish" && (
          <Finish reservation={reservation as IReservation} {...passwords} />
        )}
      </Content>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}
        >
          <MyCircularProgress color="#00FE79" size={30} />
        </div>
      )}
      <IconPowered theme={theme} />
    </Container>
  );
};
