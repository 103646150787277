import React from "react";
import { AppRoutes } from "./appRoutes";

export const Routes: React.FC = () => {
  return (
    <div style={{ display: "flex", flex: 1 }}>
      <AppRoutes />
    </div>
  );
};
