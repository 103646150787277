import { CgArrowLeft } from "react-icons/cg";
import { inactiveItens } from "../../../../../../../../../theme";
import Button from "../../../../../../../../../components/Buttons/Button";
import { PhoneIcon } from "../../../../../../../../../components/SvgComponents/Icons/PhoneIcon";
import { FileIcon } from "../../../../../../../../../components/SvgComponents/Icons/FileIcon";

export interface IStartDocuments {
  onNext: (document_mode: "file" | "photo") => void;
  goBack: () => void;
  document_type: "rg" | "passport" | "license";
}

const mapper = {
  rg: {
    name: "identidade",
    description: "Carregue seu Documento de Identidade",
  },
  passport: {
    name: "passaporte",
    description: "Carregue seu Passaporte",
  },
  license: {
    name: "CNH",
    description: "Carregue sua Carteira de Habilitação",
  },
};

export const SelectDocumentMode: React.FC<IStartDocuments> = ({
  goBack,
  onNext,
  document_type,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "0 20px",
        flex: 1,
      }}
    >
      <div
        style={{ display: "flex", flexDirection: "column", gap: 10, flex: 1 }}
      >
        <div
          style={{
            display: "flex",
            gap: 10,
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: 18,
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              gap: 5,
              position: "relative",
              justifyContent: "center",
              flex: 1,
            }}
          >
            <div
              style={{ position: "absolute", left: 0 }}
              className="pointer"
              onClick={() => goBack()}
            >
              <CgArrowLeft color={inactiveItens.light} size={20} />
            </div>
            <div>Enviar {mapper?.[document_type]?.name}</div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 20,
            flex: 1,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 5,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                fontSize: 18,
                fontWeight: "bold",
                maxWidth: 300,
                textAlign: "center",
                marginTop: 20,
              }}
            >
              Escolha a forma de envio
            </div>
            <div
              style={{
                fontSize: 14,
                color: "#5B5B5B",
                maxWidth: 250,
                textAlign: "center",
              }}
            >
              Selecione uma das opções de documento de identificação para
              cadastrar.
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: 16,
            }}
          >
            <div
              style={{
                display: "flex",
                padding: 16,
                borderRadius: 8,
                gap: 10,
                border: "1px solid #8D8D8D",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => onNext("photo")}
            >
              <PhoneIcon />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{ color: "#3A3A3A", fontWeight: "bold", fontSize: 14 }}
                >
                  Tirar uma foto
                </div>
                <div style={{ color: "#3A3A3A", fontSize: 12 }}>
                  Use um celular ou tablet
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                padding: 16,
                borderRadius: 8,
                gap: 10,
                border: "1px solid #8D8D8D",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => onNext("file")}
            >
              <FileIcon />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{ color: "#3A3A3A", fontWeight: "bold", fontSize: 14 }}
                >
                  Carregar um arquivo
                </div>
                <div style={{ color: "#3A3A3A", fontSize: 12 }}>
                  Use um celular ou tablet
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
