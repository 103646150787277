import * as React from "react";
import { primary } from "../../../../theme";

export const InstructionStep = ({ size, color, ...props }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#AEAEAE"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m5.95 10 2.7 2.7 5.4-5.4M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
    />
  </svg>
);
