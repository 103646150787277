import styled from "styled-components";
import { Column, Line } from "../../style";
import FormCheckLabel from "react-bootstrap/Form";
import { borderDisabled, fail } from "../../theme";

export const GroupTitle = styled.div`
  align-items: center;
  margin-top: 20px;
  margin-bottom: -10px;
  font-weight: 600;
`;

export const FieldsLine = styled(Line)`
  gap: 20px;
  flex-wrap: wrap;
`;

export const ContainerGroup = styled(Column)`
  gap: 20px;
`;

export const ContainerForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const BaseSelect = styled(FormCheckLabel.Select)<{
  haveError: boolean;
}>`
  position: relative;
  width: 100%;
  min-width: 250px;
  flex: 1;
  transition: 0s all;
  border: 1px solid ${(props) => (props.haveError ? fail : "#8D8D8D")} !important;

  :disabled {
    border: 1px solid ${borderDisabled.light} !important;
    color: #999999;
  }

  :focus {
    box-shadow: none !important;
    border: 1px solid #292929 !important;
    outline: 1px solid #292929 !important;
  }

  option {
    border-radius: 0px !important;
  }
`;
