import styled from "styled-components";

export const Form = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  padding: 0 20px;
`;
