import * as React from "react";
import { primary } from "../../../../theme";

export const SelfieIcon = ({ size, color, ...props }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      stroke="#AEAEAE"
      strokeLinecap="round"
      strokeWidth={2.5}
      d="M8.4 34c-2.24 0-3.36 0-4.216-.436a4 4 0 0 1-1.748-1.748C2 30.96 2 29.84 2 27.6m32 0c0 2.24 0 3.36-.436 4.216a4 4 0 0 1-1.748 1.748C30.96 34 29.84 34 27.6 34m0-32c2.24 0 3.36 0 4.216.436a4 4 0 0 1 1.748 1.748C34 5.04 34 6.16 34 8.4M8.4 2c-2.24 0-3.36 0-4.216.436a4 4 0 0 0-1.748 1.748C2 5.04 2 6.16 2 8.4M18 17.5a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm0 0c4.413 0 8 3.577 8 7.99a.01.01 0 0 1-.01.01m-7.99-8c-4.413 0-8 3.577-8 7.99 0 .005.005.01.01.01"
    />
  </svg>
);

export const SelfieIcon2 = ({ size, color, ...props }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      stroke="#3A3A3A"
      strokeLinecap="round"
      strokeWidth={2.5}
      d="M8.4 34c-2.24 0-3.36 0-4.216-.436a4 4 0 0 1-1.748-1.748C2 30.96 2 29.84 2 27.6m32 0c0 2.24 0 3.36-.436 4.216a4 4 0 0 1-1.748 1.748C30.96 34 29.84 34 27.6 34m0-32c2.24 0 3.36 0 4.216.436a4 4 0 0 1 1.748 1.748C34 5.04 34 6.16 34 8.4M8.4 2c-2.24 0-3.36 0-4.216.436a4 4 0 0 0-1.748 1.748C2 5.04 2 6.16 2 8.4"
    />
    <path
      stroke="#00FE79"
      strokeLinecap="round"
      strokeWidth={2.5}
      d="M18 17.5a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm0 0c4.413 0 8 3.577 8 7.99a.01.01 0 0 1-.01.01m-7.99-8c-4.413 0-8 3.577-8 7.99 0 .005.005.01.01.01"
    />
  </svg>
);
