import { CgArrowLeft } from "react-icons/cg";
import { inactiveItens } from "../../../../../../../../../theme";
import CNH from "./../../../../../../../../../assets/CNH.svg";
import Passport from "./../../../../../../../../../assets/PASSPORT.svg";
import RG from "./../../../../../../../../../assets/RG.svg";
import Button from "../../../../../../../../../components/Buttons/Button";

export interface IStartDocuments {
  onNext: () => void;
  goBack: () => void;
  document_type: "rg" | "passport" | "license";
}

const mapper = {
  rg: {
    name: "identidade",
    image: RG,
    description: "Carregue seu Documento de Identidade",
  },
  passport: {
    name: "passaporte",
    image: Passport,
    description: "Carregue seu Passaporte",
  },
  license: {
    name: "CNH",
    image: CNH,
    description: "Carregue sua Carteira de Habilitação",
  },
};

export const SetupDocumentType: React.FC<IStartDocuments> = ({
  goBack,
  onNext,
  document_type,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "0 20px",
        flex: 1,
      }}
    >
      <div
        style={{ display: "flex", flexDirection: "column", gap: 10, flex: 1 }}
      >
        <div
          style={{
            display: "flex",
            gap: 10,
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: 18,
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              gap: 5,
              position: "relative",
              justifyContent: "center",
              flex: 1,
            }}
          >
            <div
              style={{ position: "absolute", left: 0 }}
              className="pointer"
              onClick={() => goBack()}
            >
              <CgArrowLeft color={inactiveItens.light} size={20} />
            </div>
            <div>Enviar {mapper?.[document_type]?.name}</div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 20,
            flex: 1,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 5,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={mapper?.[document_type]?.image} />
            <div
              style={{
                fontSize: 18,
                fontWeight: "bold",
                maxWidth: 300,
                textAlign: "center",
                marginTop: 20,
              }}
            >
              {mapper?.[document_type]?.description}
            </div>
            <div
              style={{
                fontSize: 14,
                color: "#5B5B5B",
                maxWidth: 300,
                textAlign: "center",
              }}
            >
              Precisamos checar a sua identidade para concluir o seu check-in.
            </div>
          </div>
        </div>
        <Button
          background={"#6BFEB1"}
          color={"#292929"}
          style={{ marginTop: 30 }}
          onClick={onNext}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flex: 1,
              padding: "0px 10px",
            }}
          >
            Continuar
          </div>
        </Button>
      </div>
    </div>
  );
};
