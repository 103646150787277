const policyPrivacyLink = "https://wehousy.com.br/novosite/politica-de-privacidade/"
const useTermsLink = "https://wehousy.com.br/novosite/termos-de-uso-moradores/"
const helpLink = "https://www.reservas.wehousy.com.br/pt/central-de-ajuda/"
const locationTermsLink = "https://wehousy.com.br/novosite/termos-de-uso-moradores/"
const pointsProgramLink = "https://wehousy.com.br/novosite/programa-de-pontos/"
const wppLink = "https://wa.me/5577999880456/"
const feedbackLink = "https://wa.me/5577999880456/"
const cancelPolicyLink = "https://wehousy.com.br/novosite/politica-de-cancelamento-moradores/"

export const links = {
    policyPrivacyLink,
    useTermsLink,
    helpLink,
    locationTermsLink,
    pointsProgramLink,
    wppLink,
    feedbackLink,
    cancelPolicyLink
}