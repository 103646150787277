import * as React from "react";

export const BathroomIcon = ({ size, color, ...props }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      stroke="#292929"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M5.7 13.4h12.6m-2.1-2.8V7.45a1.05 1.05 0 0 0-2.1 0v.35m-7 8.4-.7 1.4m10.5-1.4.7 1.4m-7.56-.7h3.92A5.04 5.04 0 0 0 19 11.86a1.26 1.26 0 0 0-1.26-1.26H6.26A1.26 1.26 0 0 0 5 11.86a5.04 5.04 0 0 0 5.04 5.04Z"
    />
  </svg>
);
