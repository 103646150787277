import * as React from "react";
import { primary } from "../../../../theme";

export const TrashIcon = ({ size, color, ...props }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#292929"
      d="M5.321 6.444a.75.75 0 0 0-1.5 0h1.5Zm-1.5 6.223a.75.75 0 0 0 1.5 0h-1.5ZM8.18 6.444a.75.75 0 1 0-1.5 0h1.5Zm-1.5 6.223a.75.75 0 0 0 1.5 0h-1.5ZM2.494 14.83l.364-.656-.364.655Zm-.624-.68.68-.318-.68.318Zm8.26 0-.68-.318.68.318Zm-.624.68-.364-.656.364.655ZM1 3.36a.75.75 0 0 0 0 1.5v-1.5Zm10 1.5a.75.75 0 0 0 0-1.5v1.5Zm-8.25-.75a.75.75 0 0 0 1.5 0h-1.5Zm5 0a.75.75 0 0 0 1.5 0h-1.5ZM3.821 6.444v6.223h1.5V6.444h-1.5Zm2.858 0v6.223h1.5V6.444h-1.5Zm2.857-2.333v8.4h1.5v-8.4h-1.5ZM8 14.25H4v1.5h4v-1.5ZM.964 4.111v8.4h1.5v-8.4h-1.5ZM4 14.25c-.413 0-.675 0-.872-.018-.186-.017-.246-.044-.27-.057l-.728 1.311c.282.156.575.214.865.24.28.025.618.024 1.005.024v-1.5ZM.964 12.511c0 .424 0 .784.022 1.078.022.3.072.596.205.88l1.358-.636c-.023-.05-.051-.139-.067-.356a14.465 14.465 0 0 1-.018-.966h-1.5Zm1.894 1.664a.749.749 0 0 1-.309-.342l-1.358.636c.2.428.525.787.94 1.017l.727-1.311Zm6.678-1.664c0 .447 0 .74-.018.966-.016.217-.044.307-.067.356l1.358.636c.133-.284.183-.58.205-.88.022-.294.022-.654.022-1.078h-1.5ZM8 15.75c.387 0 .726 0 1.005-.024.29-.026.583-.084.865-.24l-.728-1.311c-.024.013-.084.04-.27.057-.197.017-.458.018-.872.018v1.5Zm1.45-1.917a.749.749 0 0 1-.308.342l.728 1.311c.414-.23.74-.59.94-1.018l-1.36-.635ZM1 4.86h.714v-1.5H1v1.5Zm.714 0h8.572v-1.5H1.714v1.5Zm8.572 0H11v-1.5h-.714v1.5ZM4.25 3.49c0-.957.78-1.739 1.75-1.739V.25a3.244 3.244 0 0 0-3.25 3.239h1.5ZM6 1.75c.97 0 1.75.782 1.75 1.739h1.5A3.244 3.244 0 0 0 6 .25v1.5ZM2.75 3.489v.622h1.5V3.49h-1.5Zm5 0v.622h1.5V3.49h-1.5Z"
    />
  </svg>
);
