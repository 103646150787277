import * as React from "react";
import { primary } from "../../../../theme";

export const BadQuality = ({ size, color, ...props }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#DE4444"
      d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m8.4 15.6 7.2-7.2M15.6 15.6 8.4 8.4"
    />
  </svg>
);

export const BadQuality2 = ({ size, color, ...props }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={56}
    height={56}
    fill="none"
    {...props}
  >
    <path
      fill="#DE4444"
      d="M28 56c15.464 0 28-12.536 28-28S43.464 0 28 0 0 12.536 0 28s12.536 28 28 28Z"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m19.602 36.4 16.8-16.8M36.402 36.4l-16.8-16.8"
    />
  </svg>
);
