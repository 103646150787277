import Button from "../../../../components/Buttons/Button";
import { Form } from "./style";
import { useForm } from "react-hook-form";
import { secondary } from "../../../../theme";
import { useApi } from "../../../../hooks/api";
import { links } from "../../../../utils/links";
import { IGroupProps } from "../../../../interfaces";
import { useGlobal } from "../../../../hooks/global";
import { FormEvent, useCallback, useRef, useState } from "react";
import { MagicButton } from "../../../../components/Buttons/MagicButton";
import { validateCPF, validateDate } from "../../../../utils/validatesFields";
import {
  GenericForm,
  IGenericFormRefProps,
} from "../../../../components/GenericForm";
import { MyCircularProgress } from "../../../../components/MyCircularProgress";
import { maskFunctions } from "../../../../services/maskServices";

interface IStartProps {
  onSave: (reservation: any) => void;
  defaultReservationId?: string;
  defaultCheckInDate?: string;
}

export const Start: React.FC<IStartProps> = ({
  onSave,
  defaultReservationId,
  defaultCheckInDate,
}) => {
  const [loading, setLoading] = useState(false);

  const form_ref = useRef<IGenericFormRefProps>(null);
  const [acceptTerms, setAcceptTerms] = useState(false);

  const { notify } = useGlobal();
  const { api } = useApi();

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setError,
    setValue,
    trigger,
    unregister,
  } = useForm({
    defaultValues: {
      document_type: { label: "CPF", value: "cpf" },
      reservation_stays_id: defaultReservationId ?? undefined,
      check_in_date: defaultCheckInDate
        ? maskFunctions.date.mask(
            defaultCheckInDate.split("-").reverse().join("/")
          )
        : undefined,
    } as any,
    mode: "all",
  });

  const _form = watch();

  const groups: IGroupProps[] = [
    {
      name: "",
      label: "",
      fields: [
        [
          {
            name: "reservation_stays_id",
            label: "Código da reserva",
            type: "input",
            required: true,
          },
        ],
        [
          {
            name: "check_in_date",
            label: "Data do Check-in",
            type: "input",
            required: true,
            validate: validateDate,
            mask: "date",
            noUpperCase: true,
          },
        ],
      ],
    },
  ];

  const onSubmit = useCallback(async () => {
    setLoading(true);

    try {
      const form = form_ref.current?.getForm();
      const _form = {} as any;

      const document_type = form["document_type"];
      const document_number =
        document_type === "cpf"
          ? form["document_value_cpf"]
          : form["document_value_others"];

      _form["document_type"] = document_type;
      _form["check_in_date"] = new Date(form["check_in_date"])
        ?.toISOString()
        ?.split("T")[0];
      _form["reservation_stays_id"] = form["reservation_stays_id"];
      _form["document_number"] = document_number;

      const { data: reservation } = await api.post("/check-ins/start", _form);

      onSave(reservation);
    } catch (err: any) {
      console.log(err.response);
      const error = err.response ? err.response?.data : "SERVER ERROR";
      console.log(error);
      if (error.field)
        setError(error.field, { type: "manual", message: error.detail });
      else notify("ERRO INTERNO DO SISTEMA", "error");
    }

    setLoading(false);
  }, [form_ref, _form]);

  const handleStopPropagation = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e?.stopPropagation();
      handleSubmit(onSubmit)(e);
    },
    [handleSubmit, onSubmit, form_ref, _form]
  );

  return (
    <>
      <div
        style={{
          display: loading ? "none" : "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 10,
          flex: 1,
        }}
      >
        <div
          style={{
            fontWeight: "bold",
            fontSize: 24,
            textAlign: "center",
            maxWidth: 300,
          }}
        >
          Preparado para iniciar seu check-in?
        </div>
        <div
          style={{
            fontSize: 16,
            textAlign: "center",
            maxWidth: 320,
            marginBottom: 20,
          }}
        >
          Com o Web check-in, você resolve os últimos detalhes da sua reserva
          antes de chegar na sua acomodação
        </div>

        <Form onSubmit={handleStopPropagation}>
          <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
            <GenericForm
              ref={form_ref}
              groups={groups}
              _form={_form}
              control={control}
              errors={errors}
              trigger={trigger}
              setValue={setValue}
              register={register}
              noTrigger
              containerStyle={{ width: "100%" }}
            />
            <div
              style={{
                marginTop: 30,
                marginLeft: -5,
                display: "flex",
                gap: 5,
                alignItems: "flex-start",
                cursor: "pointer",
              }}
              onClick={() => setAcceptTerms((old) => !old)}
            >
              <MagicButton
                checked={acceptTerms}
                onClick={() => setAcceptTerms((old) => !old)}
                theme="light"
                type="checkbox"
                label={""}
                labelStyle={{
                  textTransform: "none",
                  fontWeight: 600,
                  fontSize: 13,
                }}
              />
              <div
                style={{
                  marginTop: -11,
                  cursor: "pointer",
                  userSelect: "none",
                  fontSize: 13,
                }}
              >
                Ao continuar este check-in, você confirma que leu e concordou
                com nossa{" "}
                <a
                  target="_blank"
                  href={links.policyPrivacyLink}
                  style={{
                    textDecoration: "none",
                    color: "#000",
                    fontWeight: "bold",
                  }}
                >
                  Política de privacidade
                </a>
                .
              </div>
            </div>
          </div>
          <Button
            disabled={!acceptTerms}
            background={secondary.light}
            color={"#292929"}
            type="submit"
            style={{ marginTop: 30 }}
          >
            Iniciar web check-in
          </Button>
        </Form>
      </div>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}
        >
          <MyCircularProgress color="#00FE79" />
        </div>
      )}
    </>
  );
};
