import * as React from "react";
import { primary } from "../../../../theme";

export const AddGuest = ({ size, color, ...props }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={17}
    fill="none"
    {...props}
  >
    <path
      stroke="#292929"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9 11H5.4c-1.116 0-1.675 0-2.129.138a3.2 3.2 0 0 0-2.133 2.133C1 13.725 1 14.283 1 15.4m13.6 0v-4.8M12.2 13H17m-6-8.4a3.6 3.6 0 1 1-7.2 0 3.6 3.6 0 0 1 7.2 0Z"
    />
  </svg>
);
