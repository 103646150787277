import * as React from "react";
import { primary } from "../../../../theme";

export const GuestCheck = ({ size = 16, color, ...props }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    {...props}
  >
    <path fill="#00FE79" d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Z" />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m4.398 8 2.4 2.4 4.8-4.8"
    />
  </svg>
);

export const GuestCheck2 = ({ size = 16, color, ...props }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={56}
    height={56}
    fill="none"
    {...props}
  >
    <path
      fill="#00FE79"
      d="M28 56c15.464 0 28-12.536 28-28S43.464 0 28 0 0 12.536 0 28s12.536 28 28 28Z"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="m15.398 28 8.4 8.4 16.8-16.8"
    />
  </svg>
);
