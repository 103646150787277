import React from "react";

export const LogoIcon = ({ size, ...props }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={155}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fill="#00FE79"
      d="M55.187 25.294h-5.046c-.61 0-1.103-.492-1.103-1.102v-2.736c0-.312.132-.613.37-.82l2.524-2.261a1.1 1.1 0 0 1 1.471 0l2.525 2.261c.234.21.37.508.37.82v2.736c0 .61-.494 1.102-1.103 1.102h-.008Z"
    />
    <path
      fill="#3A3A3A"
      d="m28.286 8.218-6.028 16.79h-4.41L14.16 13.355l-3.688 11.654h-4.41L0 8.219h4.907l3.458 11.39 3.654-11.39h4.38l3.654 11.39 3.533-11.39h4.704-.004ZM45.618 18.096H32.282c.56 2.141 2.273 3.522 4.576 3.522 1.746 0 3.259-.756 3.985-1.844h4.643c-1.283 3.357-4.61 5.63-8.692 5.63-5.2 0-9.087-3.786-9.087-8.79 0-5.005 3.883-8.79 9.02-8.79 5.136 0 9.019 3.785 9.019 8.823 0 .43-.034 1.054-.132 1.449h.004ZM32.35 14.969h8.79c-.56-2.043-2.306-3.39-4.41-3.39-2.103 0-3.82 1.35-4.38 3.39ZM72.768 25.009v-9.284c0-2.272-1.45-3.751-3.654-3.751-2.37 0-3.951 1.512-3.951 3.819v9.22h-4.279V0h4.279v10.423c1.283-1.712 3.127-2.6 5.332-2.6 3.95 0 6.551 2.566 6.551 6.518v10.668h-4.278ZM79.876 16.613c0-5.004 3.883-8.79 9.02-8.79 5.136 0 9.02 3.786 9.02 8.79 0 5.005-3.884 8.79-9.02 8.79-5.137 0-9.02-3.785-9.02-8.79Zm13.63 0c0-2.634-1.942-4.643-4.61-4.643s-4.61 2.01-4.61 4.643c0 2.634 1.975 4.644 4.61 4.644 2.634 0 4.61-2.01 4.61-4.644ZM135.868 18.555c0 4.049-2.634 6.849-6.946 6.849-2.634 0-4.941-1.02-6.487-2.668l-.956 2.273h-4.41l7.243-16.79h4.907c.757 4.345 6.649 4.278 6.649 10.336Zm-4.41-.098c0-2.404-3.225-3.16-4.775-5.829l-2.766 6.552c.854 1.249 2.435 2.073 4.181 2.073 2.009 0 3.356-1.087 3.356-2.8l.004.004ZM112.199 8.218v9.024c0 2.43-1.012 3.988-3.612 3.988s-3.612-1.557-3.612-3.988V8.218h-4.226v9.656c0 4.696 2.965 7.53 7.808 7.53h.06c4.843 0 7.808-2.834 7.808-7.53V8.218h-4.226ZM150.156 8.218v9.024c0 2.43-1.012 3.988-3.612 3.988s-3.613-1.557-3.613-3.988V8.218h-4.225v9.656c0 4.696 2.965 7.53 7.808 7.53h.06c4.843 0 7.808-2.834 7.808-7.53V8.218h-4.226ZM151.639 27.951h-10.19V32h10.19v-4.049Z"
    />
  </svg>
);
