import { useState } from "react";
import { Guest, IReservation } from "../../interfaces";
import { AccommodationStep } from "./steps/accommodationStep";
import { ReservationStep } from "./steps/reservationStep";
import { GuestsStep } from "./steps/guestsStep";
import { DocumentStep } from "./steps/documentsStep";

export interface IReservationStepsProps {
  onSave: (guests: Guest[]) => void;
  reservation: IReservation;
}

export const ReservationSteps: React.FC<IReservationStepsProps> = ({
  onSave,
  reservation,
}) => {
  const [step, setStep] = useState<
    "accommodation-details" | "reservation-details" | "guests" | "documents"
  >("accommodation-details");
  const [guests, setGuests] = useState<Guest[]>(reservation.guests);

  if (step === "accommodation-details")
    return (
      <AccommodationStep
        reservation={reservation}
        onNext={() => setStep("reservation-details")}
      />
    );

  if (step === "reservation-details")
    return (
      <ReservationStep
        reservation={reservation}
        onNext={() => setStep("guests")}
        goBack={() => setStep("accommodation-details")}
      />
    );

  if (step === "guests")
    return (
      <GuestsStep
        guests={guests}
        setGuests={setGuests}
        reservation={reservation}
        onNext={() => setStep("documents")}
        goBack={() => setStep("reservation-details")}
      />
    );

  if (step === "documents") {
    return (
      <DocumentStep
        reservation={reservation}
        onNext={() => onSave(guests)}
        goBack={() => setStep("guests")}
        guests={guests}
        setGuests={setGuests}
      />
    );
  }
  return <></>;
};
