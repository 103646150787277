import Button from "../../../../../../../../../components/Buttons/Button";
import { CgArrowLeft } from "react-icons/cg";
import { inactiveItens } from "../../../../../../../../../theme";
import {
  SelfieIcon,
  SelfieIcon2,
} from "../../../../../../../../../components/SvgComponents/Icons/SelfieIcon";
import {
  GenericDocumentIcon,
  GenericDocumentIcon2,
} from "../../../../../../../../../components/SvgComponents/Icons/GenericDocumentIcon";

export interface IStartDocuments {
  onNext: () => void;
  goBack: () => void;
}

export const StartSelfie: React.FC<IStartDocuments> = ({ goBack, onNext }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "0 20px",
        flex: 1,
      }}
    >
      <div
        style={{ display: "flex", flexDirection: "column", gap: 10, flex: 1 }}
      >
        <div
          style={{
            display: "flex",
            gap: 10,
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: 18,
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              gap: 5,
              position: "relative",
              justifyContent: "center",
              flex: 1,
            }}
          >
            <div
              style={{ position: "absolute", left: 0 }}
              className="pointer"
              onClick={() => goBack()}
            >
              <CgArrowLeft color={inactiveItens.light} size={20} />
            </div>
            <div>Documentação</div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 20,
            flex: 1,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ fontSize: 18, fontWeight: "bold" }}>Falta pouco!</div>
            <div
              style={{
                fontSize: 14,
                color: "#5B5B5B",
                textAlign: "center",
                width: 300,
              }}
            >
              Agora, capture uma selfie para concluir o seu web check-in.
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              alignItems: "center",
            }}
          >
            <GenericDocumentIcon2 />
            <div style={{ color: "#AEAEAE", fontWeight: "bold", fontSize: 18 }}>
              1. Enviar documento
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              alignItems: "center",
            }}
          >
            <SelfieIcon2 />
            <div style={{ color: "#3A3A3A", fontWeight: "bold", fontSize: 18 }}>
              2. Capturar selfie
            </div>
          </div>
        </div>
      </div>
      <Button
        background={"#6BFEB1"}
        color={"#292929"}
        style={{ marginTop: 30 }}
        onClick={onNext}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flex: 1,
            padding: "0px 10px",
          }}
        >
          Capturar selfie
        </div>
      </Button>
    </div>
  );
};
