import * as React from "react";

export const BedIcon = ({ size, color, ...props }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      stroke="#292929"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="m5 15.15 1.81-4.605a4.2 4.2 0 0 0 .29-1.536V8.15a1.4 1.4 0 0 1 1.4-1.4h7a1.4 1.4 0 0 1 1.4 1.4v.86c0 .525.099 1.046.29 1.535L19 15.15M7.1 9.55h2.1m5.6 0h2.1m-10.5 7.7h11.2a1.4 1.4 0 1 0 0-2.8H6.4a1.4 1.4 0 1 0 0 2.8Zm4.2-5.6h2.8a1.4 1.4 0 1 0 0-2.8h-2.8a1.4 1.4 0 1 0 0 2.8Z"
    />
  </svg>
);
