import { AiOutlineCloseCircle } from "react-icons/ai";
import Button from "../../../../../../../components/Buttons/Button";
import { Guest } from "../../../../../interfaces";
import { useForm } from "react-hook-form";
import {
  validateCPF,
  validateDate,
  validateEmail,
  validatePhone,
} from "../../../../../../../utils/validatesFields";
import { IGroupProps } from "../../../../../../../interfaces";
import {
  GenericForm,
  IGenericFormRefProps,
} from "../../../../../../../components/GenericForm";
import { FormEvent, useCallback, useRef } from "react";
import { maskFunctions } from "../../../../../../../services/maskServices";

interface ICreateOrEditGuest {
  defaultGuest: Guest | null;
  onSave: (guest: Guest) => void;
  onEdit: (guest: Guest, editIndex: number) => void;
  editIndex: number;
  onCancel: () => void;
}

export const CreateOrEditGuest = ({
  defaultGuest,
  onSave,
  onCancel,
  onEdit,
  editIndex,
}: ICreateOrEditGuest) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setError,
    setValue,
    trigger,
    unregister,
  } = useForm({
    defaultValues: defaultGuest
      ? {
          ...defaultGuest,
          // birth: maskFunctions.date.mask(defaultGuest.birth),
          document_type: {
            label: defaultGuest.cpf ? "CPF" : "Passaporte",
            value: defaultGuest.cpf ? "cpf" : "passport",
          },
          document_value_cpf: maskFunctions.cpf.mask(defaultGuest.cpf),
          phone: maskFunctions.phone.mask(defaultGuest.phone),
          document_value_others: defaultGuest.passport,
          type: {
            label: defaultGuest.type === "adult" ? "Adulto" : "Criança",
            value: defaultGuest.type === "adult" ? "adult" : "children",
          },
        }
      : ({
          type: { label: "Adulto", value: "adult" },
          document_type: { label: "CPF", value: "cpf" },
        } as any),
    mode: "all",
  });

  const button_submit_ref = useRef<HTMLButtonElement>(null);

  const form_ref = useRef<IGenericFormRefProps>(null);

  const _form = watch();

  const groups: IGroupProps[] = [
    {
      name: "",
      label: "",
      fields: [
        [
          {
            name: "name",
            label: "Nome completo",
            type: "input",
            required: true,
            noUpperCase: true,
            getIsDisabled: (data: any) => editIndex === 0,
          },
        ],
        // [
        //   {
        //     name: "birth",
        //     label: "Nascimento",
        //     type: "input",
        //     required: true,
        //     noUpperCase: true,
        //     mask: "date",
        //     validate: validateDate,
        //   },
        // ],
        [
          {
            name: "type",
            label: "Tipo de hóspede",
            type: "select-fixed",
            options: [
              { label: "Adulto", value: "adult" },
              { label: "Criança", value: "children" },
            ],
            required: true,
          },
        ],
        [
          {
            name: "email",
            label: "E-mail",
            type: "input",
            required: true,
            validate: validateEmail,
            noUpperCase: true,
          },
        ],
        [
          {
            name: "phone",
            label: "Celular",
            type: "input",
            required: true,
            validate: validatePhone,
            mask: "phone",
            noUpperCase: true,
          },
        ],
        [
          {
            name: "document_type",
            label: "Tipo do Documento",
            type: "select-fixed",
            options: [
              { label: "CPF", value: "cpf" },
              { label: "Passaporte", value: "passport" },
            ],
            required: true,
          },
        ],
        [
          {
            name: "document_value_cpf",
            label: "Documento",
            type: "input",
            mask: "cpf",
            required: true,
            validate: validateCPF,
            getIsDisabled: (data: any) => !data["document_type"]?.value,
            canSee: (data: any) => data["document_type"]?.value === "cpf",
            noUpperCase: true,
          },
        ],
        [
          {
            name: "document_value_others",
            label: "Número do documento",
            type: "input",
            required: true,
            getIsDisabled: (data: any) => !data["document_type"]?.value,
            canSee: (data: any) => data["document_type"]?.value !== "cpf",
            noUpperCase: true,
          },
        ],
      ],
    },
  ];

  const onSubmit = useCallback(async () => {
    const form = form_ref.current?.getForm();
    const _form = {} as any;

    const document_type = form["document_type"];
    const document_number =
      document_type === "cpf"
        ? form["document_value_cpf"]
        : form["document_value_others"];

    _form[document_type] = document_number;
    _form["type"] = form["type"];
    _form["name"] = form["name"];
    _form["email"] = form["email"];
    _form["phone"] = form["phone"];
    _form["birth"] = form["birth"];

    if (defaultGuest) onEdit(_form, editIndex);
    else onSave(_form);
  }, [form_ref, editIndex, defaultGuest]);

  const handleStopPropagation = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e?.stopPropagation();
      handleSubmit(onSubmit)(e);
    },
    [handleSubmit, onSubmit, form_ref, _form]
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "0 20px",
        flex: 1,
      }}
    >
      <div
        style={{ display: "flex", flexDirection: "column", gap: 10, flex: 1 }}
      >
        <div
          style={{
            display: "flex",
            gap: 10,
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 10,
            position: "relative",
          }}
        >
          <div style={{ fontWeight: "bold", fontSize: 18 }}>
            {defaultGuest ? "Editar hóspede" : "Adicionar hóspede"}
          </div>
          <div
            onClick={onCancel}
            style={{
              cursor: "pointer",
              padding: 10,
              position: "absolute",
              right: 0,
            }}
          >
            <AiOutlineCloseCircle size={20} />
          </div>
        </div>
        <form onSubmit={handleStopPropagation}>
          <GenericForm
            ref={form_ref}
            groups={groups}
            _form={_form}
            control={control}
            errors={errors}
            trigger={trigger}
            setValue={setValue}
            register={register}
            noTrigger
            containerStyle={{ width: "100%" }}
          />
          <button
            ref={button_submit_ref}
            style={{ display: "none" }}
            type="submit"
          />
        </form>
      </div>
      <Button
        background={"#00FE79"}
        color={"#292929"}
        type="submit"
        style={{ marginTop: 30 }}
        onClick={() => button_submit_ref?.current?.click()}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flex: 1,
            padding: "0px 10px",
          }}
        >
          {defaultGuest ? "Salvar hóspede" : "Adicionar hóspede"}
        </div>
      </Button>
    </div>
  );
};
