import Button from "../components/Buttons/Button";
import toast, { ToastOptions } from "react-hot-toast";
import notificationSound from "./../assets/notificationSound.mp3";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Modal } from "@material-ui/core";
import { BiCheckCircle } from "react-icons/bi";
import { FiAlertOctagon } from "react-icons/fi";
import { debounceEvent } from "../utils/debounceEvent";
import { TiDelete, TiDeleteOutline } from "react-icons/ti";
import { Computer } from "../components/Animations/Computer";
import { calculateVH } from "../services/calculateVH.service";
import { franchiseProps, themeTypes } from "../interfaces/index";
import { detectMobileService } from "../services/detectMobile.service";
import {
  background,
  fail,
  inactiveItens,
  info,
  secondary,
  success,
} from "../theme";
import {
  Container,
  IconContainer,
  MessageContainer,
  NotifyContainer,
} from "./style";

export interface menuItemsProps {
  name: string;
  to: string;
  icon: string;
  iconComponent?: React.ReactElement;
  package: string;
  subMenus: menuItemsProps[];
}

type notifyTypes = "error" | "success" | "alert";

interface GlobalContextData {
  menuOpen: boolean;
  subMenuOpen: boolean;
  theme: themeTypes;
  menuItemSelected: menuItemsProps;
  subMenuItemSelected: menuItemsProps;
  paginationLimit: number;
  gerencianet_min_value: number;
  multiple_mxs: number;
  gerencianet_min_credits: number;
  seeNotification: boolean;
  setSeeNotification: React.Dispatch<React.SetStateAction<boolean>>;
  setSubMenuItemSelected: React.Dispatch<React.SetStateAction<menuItemsProps>>;
  setMenuItemSelected: React.Dispatch<React.SetStateAction<menuItemsProps>>;
  setTheme(value: themeTypes): void;
  setMenuOpen(value: boolean): void;
  setSubMenuOpen(value: boolean): void;
  notify: (message: string, type: notifyTypes) => void;
  notifyBottom: (message: string, type: notifyTypes) => void;
  notifyOnly: (message: string, type: notifyTypes) => void;
  clickOnApp(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
  setOpenSelect(value: string): void;
  openSelect: string;
  controlResize: boolean;
  loadingResize: boolean;
  isMobile: boolean;
  getCorrectMaxWidth: () => string;
  playNotificationSound: (_activity: boolean) => void;
  activity: boolean;
  containerGlobalRef: React.RefObject<HTMLDivElement>;
}

const GlobalContext = createContext<GlobalContextData>({} as GlobalContextData);

const IconToast = {
  error: <TiDeleteOutline style={{ marginRight: 10 }} size={26} fill={fail} />,
  success: (
    <BiCheckCircle style={{ marginRight: 10 }} size={26} fill={success} />
  ),
  alert: <FiAlertOctagon style={{ marginRight: 10 }} size={26} stroke={info} />,
};

const notifyProps: ToastOptions = {
  position: "top-right",
  duration: 5000,
  style: {
    padding: 0,
    hyphens: "auto",
    display: "flex",
    alignItems: "center",
  },
  className: "notify-card",
};

const notifyBottomProps: ToastOptions = {
  ...notifyProps,
  position: "bottom-center",
  style: { ...notifyProps.style, maxWidth: 400 },
};

export const debounceService_1 = debounceEvent();

export const debounceService_2 = debounceEvent();

export const GlobalProvider: React.FC = ({ children }) => {
  const multiple_mxs = 100;
  const paginationLimit = 10;
  const gerencianet_min_value = 50;
  const gerencianet_min_credits = gerencianet_min_value * multiple_mxs;
  const containerGlobalRef = useRef<HTMLDivElement>(null);

  const [activity, setActivity] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [controlResize, setControlResize] = useState(false);
  const [openSelect, setOpenSelect] = useState<string>("-1");
  const [subMenuOpen, setSubMenuOpen] = useState<boolean>(false);
  const [seeNotification, setSeeNotification] = React.useState(true);
  const [openModalWarningMobile, setOpenModalWarningMobile] =
    useState<boolean>(true);
  const [menuItemSelected, setMenuItemSelected] = useState<menuItemsProps>(
    {} as menuItemsProps
  );
  const [subMenuItemSelected, setSubMenuItemSelected] =
    useState<menuItemsProps>({} as menuItemsProps);

  const playNotificationSound = useCallback((_activity) => {
    if (!_activity) {
      const audio = new Audio(notificationSound);
      audio.loop = false;
      audio.play().catch((error) => {
        if (error.name === "NotAllowedError") return;
        console.error(error);
      });
    }
  }, []);

  const resetTimer = () => {
    setActivity(true);
    debounceService_1(() => {
      setActivity(false);
    }, 30000);
  };

  document.addEventListener("mousemove", resetTimer);

  document.addEventListener("keydown", resetTimer);

  window.onresize = () => setControlResize((prev) => !prev);

  const [theme, setTheme] = useState<themeTypes>(() => {
    const theme_storage = localStorage.getItem("@we-housy:theme");
    if (theme_storage === null) {
      localStorage.setItem("@we-housy:theme", "light");
      return "light";
    }
    return theme_storage as themeTypes;
  });

  const [menuOpen, setMenuOpen] = useState<boolean>(() => {
    if (window.innerWidth < 600) return false;
    const menu_open_storage = localStorage.getItem("@we-housy:menuOpen");
    if (menu_open_storage === null) {
      localStorage.setItem("@we-housy:menuOpen", "true");
      return true;
    }
    return menu_open_storage === "true";
  });

  const notify = (message: string, type: notifyTypes) =>
    toast(
      (t) => (
        <NotifyContainer onClick={() => toast.dismiss(t.id)}>
          <IconContainer>{IconToast[type]}</IconContainer>
          <MessageContainer>{message}</MessageContainer>
        </NotifyContainer>
      ),
      notifyProps
    );

  const notifyOnlyAction = (message: string, type: notifyTypes) =>
    toast(
      (t) => (
        <NotifyContainer onClick={() => toast.dismiss(t.id)}>
          <IconContainer>{IconToast[type]}</IconContainer>
          <MessageContainer>{message}</MessageContainer>
        </NotifyContainer>
      ),
      notifyProps
    );

  const notifyBottomAction = (message: string, type: notifyTypes) =>
    toast(
      (t) => (
        <NotifyContainer id="toast-bottom" onClick={() => toast.dismiss(t.id)}>
          <IconContainer>{IconToast[type]}</IconContainer>
          <MessageContainer>{message}</MessageContainer>
        </NotifyContainer>
      ),
      notifyBottomProps
    );

  const notifyOnly = useCallback((message: string, type: notifyTypes) => {
    toast.dismiss();
    setTimeout(() => {
      toast.dismiss();
      notifyOnlyAction(message, type);
    }, 100);
  }, []);

  const notifyBottom = useCallback((message: string, type: notifyTypes) => {
    const old_notify_bottom = document.getElementById("toast-bottom");
    if (old_notify_bottom) old_notify_bottom.click();
    notifyBottomAction(message, type);
  }, []);

  const getCorrectMaxWidth = useCallback(() => {
    let width_minus = menuOpen ? 192 : 72;
    if (window.innerWidth <= 550) width_minus = 72;

    if (isMobile) width_minus -= 12;
    return `calc(100vw - ${width_minus}px)`;
  }, [menuOpen]);

  const clickOnApp = useCallback(
    (event) => {
      let className = event?.target.getAttribute
        ? event.target.getAttribute("class")
        : "";
      className = className ? className : "";
      className = className.replace(" btn-primary", "").replace(" btn", "");

      if (className)
        className = className.split(" ")[className.split(" ").length - 1];
      if (className !== openSelect) setOpenSelect(className);
      else setOpenSelect("-1");
    },
    [openSelect]
  );

  useEffect(
    () => localStorage.setItem("@we-housy:menuOpen", `${!!menuOpen}`),
    [menuOpen]
  );

  useEffect(() => {
    localStorage.setItem("@we-housy:theme", theme);
    document.body.style.setProperty("--background-color", background[theme]);
  }, [theme]);

  useEffect(() => {
    resetTimer();
  }, []);

  useEffect(() => {
    const _isMobile = detectMobileService(
      navigator.userAgent || navigator.vendor,
      "http://detectmobilebrowser.com/mobile"
    );
    setIsMobile(_isMobile);

    calculateVH();

    if (!_isMobile) setLoading(true);
    debounceService_2(() => {
      setLoading(false);
    }, 500);
  }, [controlResize, menuOpen]);

  const modalIsOpen = (modalOpen: boolean) => {
    const user = localStorage.getItem("@we-housy:user");
    return !!(user && modalOpen && window.innerWidth < 800);
  };

  const props = {
    activity,
    playNotificationSound,
    loadingResize: loading,
    seeNotification,
    setSeeNotification,
    notifyOnly,
    gerencianet_min_credits,
    multiple_mxs,
    gerencianet_min_value,
    clickOnApp,
    openSelect,
    setOpenSelect,
    notifyBottom,
    controlResize,
    setSubMenuItemSelected,
    notify,
    setSubMenuOpen,
    subMenuOpen,
    subMenuItemSelected,
    menuOpen,
    setMenuOpen,
    paginationLimit,
    setTheme,
    theme,
    menuItemSelected,
    setMenuItemSelected,
    isMobile,
    getCorrectMaxWidth,
    containerGlobalRef,
  };

  return (
    <GlobalContext.Provider value={props}>{children}</GlobalContext.Provider>
  );
};

export function useGlobal(): GlobalContextData {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error("useGlobal must be used within an GlobalProvider");
  }
  return context;
}
