import Button from "../../../../../../components/Buttons/Button";
import { CgArrowLeft } from "react-icons/cg";
import { inactiveItens } from "../../../../../../theme";
import { Guest, IReservation } from "../../../../interfaces";
import { GuestCheck } from "../../../../../../components/SvgComponents/Icons/GuestCheck";
import { ArrowRightIcon } from "../../../../../../components/SvgComponents/Icons/ArrowRightIcon";
import { useState } from "react";
import { SendSteps } from "./sendSteps";

interface IDocumentStepProps {
  reservation: IReservation;
  onNext: () => void;
  goBack: () => void;
  guests: Guest[];
  setGuests: React.Dispatch<React.SetStateAction<Guest[]>>;
}

export const DocumentStep: React.FC<IDocumentStepProps> = ({
  goBack,
  guests,
  onNext,
  reservation,
  setGuests,
}) => {
  const [selectedGuest, setSelectedGuest] = useState<Guest | null>(null);

  if (selectedGuest?.name)
    return (
      <SendSteps
        defaultGuest={selectedGuest}
        onCancel={() => setSelectedGuest(null)}
        onNext={(guest) => {
          setGuests((prevGuests) => {
            return prevGuests.map((prevGuest) => {
              if (
                prevGuest.name === guest.name &&
                prevGuest.email === guest.email &&
                prevGuest.phone === guest.phone &&
                prevGuest.cpf === guest.cpf
              ) {
                return guest;
              }
              return prevGuest;
            });
          });
          setSelectedGuest(null);
        }}
      />
    );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "0 20px",
        flex: 1,
      }}
    >
      <div
        style={{ display: "flex", flexDirection: "column", gap: 10, flex: 1 }}
      >
        <div
          style={{
            display: "flex",
            gap: 10,
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: 18,
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              gap: 5,
              position: "relative",
              justifyContent: "center",
              flex: 1,
            }}
          >
            <div
              style={{ position: "absolute", left: 0 }}
              className="pointer"
              onClick={() => goBack()}
            >
              <CgArrowLeft color={inactiveItens.light} size={20} />
            </div>
            <div>Enviar documentos</div>
            <div
              style={{ position: "absolute", right: 0 }}
              className="pointer"
              onClick={() => goBack()}
            >
              <div style={{ color: "#8D8D8D", fontSize: 12 }}>3 de 3</div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ fontSize: 14, color: "#5B5B5B" }}>
            Escolha o hóspede e envie os documentos necessários para o check-in.
          </div>
        </div>
        {guests.map((guest, index) => (
          <>
            <div style={{ borderTop: "1px solid #EFEFEF", marginTop: 10 }} />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: 10,
                marginTop: 10,
                alignItems: "center",
                cursor: !guest?.match ? "pointer" : "default",
              }}
              onClick={() => {
                if (!guest?.match) setSelectedGuest(guest);
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                {index === 0 && (
                  <div
                    style={{
                      color: "#5B5B5B",
                      fontSize: 14,
                    }}
                  >
                    Titular da Reserva
                  </div>
                )}
                {index !== 0 && (
                  <div
                    style={{
                      color: "#5B5B5B",
                      fontSize: 12,
                    }}
                  >
                    {index}º Acompanhante
                  </div>
                )}
                <div style={{ fontSize: 18, fontWeight: "bold" }}>
                  {guest.name}
                </div>
              </div>
              {guest?.match && <GuestCheck />}
              {!guest?.match && <ArrowRightIcon />}
            </div>
          </>
        ))}
      </div>
      <Button
        background={"#6BFEB1"}
        color={"#292929"}
        style={{ marginTop: 30 }}
        onClick={onNext}
        disabled={guests.some((guest) => !guest?.match)}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flex: 1,
            padding: "0px 10px",
          }}
        >
          Realizar web check-in
          <ArrowRightIcon />
        </div>
      </Button>
    </div>
  );
};
