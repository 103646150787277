import * as React from "react";
import { primary } from "../../../../theme";

export const FileIcon = ({ size, color, ...props }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={23}
    fill="none"
    {...props}
  >
    <path
      fill="#AEAEAE"
      d="M13.75 11.8a.75.75 0 0 0-1.5 0h1.5Zm-1.5 9.6a.75.75 0 0 0 1.5 0h-1.5Zm-3.38-7.73a.75.75 0 1 0 1.06 1.06l-1.06-1.06Zm5.488-1.712.53-.53-.53.53Zm1.712 2.772a.75.75 0 1 0 1.06-1.06l-1.06 1.06Zm-3.44-3.574.23.713-.23-.713Zm.74 0-.23.713.23-.713Zm4.827-5.303.1.744-.1-.744Zm-1.569-.735.645-.383-.645.383ZM21.1 16.613a.75.75 0 0 0 .6 1.375l-.6-1.375Zm-16.93.297a.75.75 0 1 0 .86-1.23l-.86 1.23Zm8.08-5.11v9.6h1.5v-9.6h-1.5Zm-2.32 2.93 2.243-2.242-1.061-1.06L8.87 13.67l1.06 1.06Zm3.897-2.242 2.243 2.242 1.06-1.06-2.242-2.243-1.06 1.061Zm-1.654 0c.246-.246.4-.399.524-.504.117-.1.159-.113.164-.115l-.464-1.426a1.983 1.983 0 0 0-.671.397c-.182.155-.385.358-.614.587l1.06 1.061Zm2.715-1.06c-.229-.23-.432-.434-.614-.588a1.983 1.983 0 0 0-.671-.397l-.464 1.426c.005.002.047.015.164.115.125.105.278.258.524.504l1.061-1.06Zm-2.027.441a.45.45 0 0 1 .278 0l.464-1.426a1.95 1.95 0 0 0-1.206 0l.464 1.426ZM1.75 9.4A7.65 7.65 0 0 1 9.4 1.75V.25A9.15 9.15 0 0 0 .25 9.4h1.5ZM19 6.55c2.9 0 5.25 2.35 5.25 5.25h1.5A6.75 6.75 0 0 0 19 5.05v1.5Zm-.704.047c.23-.031.465-.047.704-.047v-1.5c-.306 0-.607.02-.902.06l.198 1.487ZM9.4 1.75c2.8 0 5.25 1.504 6.583 3.751l1.29-.766A9.146 9.146 0 0 0 9.4.25v1.5ZM24.25 11.8a5.251 5.251 0 0 1-3.15 4.813l.6 1.375a6.751 6.751 0 0 0 4.05-6.188h-1.5ZM5.03 15.68A7.64 7.64 0 0 1 1.75 9.4H.25a9.14 9.14 0 0 0 3.92 7.51l.86-1.23ZM18.096 5.11c-.34.046-.67-.117-.824-.375l-1.29.766c.483.813 1.42 1.215 2.313 1.096l-.198-1.487Z"
    />
  </svg>
);
