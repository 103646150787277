import * as React from "react";
import { primary } from "../../../../theme";

export const RGIcon = ({ size, color, ...props }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={26}
    fill="none"
    {...props}
  >
    <path
      stroke="#AEAEAE"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M3.4 24.78c.723.22 1.7.22 3.36.22h12.48c1.66 0 2.637 0 3.36-.22m-19.2 0a2.63 2.63 0 0 1-.434-.172 3.6 3.6 0 0 1-1.574-1.574C1 22.264 1 21.256 1 19.24V6.76c0-2.016 0-3.024.392-3.794a3.6 3.6 0 0 1 1.574-1.574C3.736 1 4.744 1 6.76 1h12.48c2.016 0 3.024 0 3.794.392a3.6 3.6 0 0 1 1.574 1.574C25 3.736 25 4.744 25 6.76v12.48c0 2.016 0 3.024-.392 3.794a3.6 3.6 0 0 1-1.574 1.574c-.136.069-.279.126-.434.173m-19.2 0c0-.971.006-1.485.092-1.917a4.8 4.8 0 0 1 3.772-3.772C7.727 19 8.284 19 9.4 19h7.2c1.115 0 1.673 0 2.136.092a4.8 4.8 0 0 1 3.772 3.772c.086.432.092.946.092 1.917M17.8 10a4.8 4.8 0 1 1-9.6 0 4.8 4.8 0 0 1 9.6 0Z"
    />
  </svg>
);
