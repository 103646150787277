import { useState } from "react";
import { Guest } from "../../../../../interfaces";
import { StartDocuments } from "./steps/startDocuments";
import { SelectDocumentType } from "./steps/selectDocumentType";
import { SetupDocumentType } from "./steps/setupDocumentType";
import { SelectDocumentMode } from "./steps/selectDocumentMode";
import { FileInstructionsPhoto } from "./steps/fileInstructionsPhoto";
import { FileInstructionsFile } from "./steps/fileInstructionsFile";

export interface ISendStepsProps {
  onCancel: () => void;
  onNext: (guest: Guest) => void;
  defaultGuest: Guest;
}

export const SendSteps: React.FC<ISendStepsProps> = ({
  onCancel,
  defaultGuest,
  onNext,
}) => {
  const [step, setStep] = useState<
    | "start"
    | "select-document-type"
    | "document-setup"
    | "select-document-mode"
    | "send-file"
  >("start");
  const [documentType, setDocumentType] = useState<
    "rg" | "passport" | "license"
  >("rg");
  const [documentMode, setDocumentMode] = useState<"file" | "photo">("file");

  if (step === "start")
    return (
      <StartDocuments
        onNext={() => setStep("select-document-type")}
        goBack={() => onCancel()}
      />
    );
  if (step === "select-document-type")
    return (
      <SelectDocumentType
        goBack={() => setStep("start")}
        onNext={(newDocumentType: string) => {
          setDocumentType(newDocumentType as "rg" | "passport" | "license");
          setStep("document-setup");
        }}
      />
    );
  if (step === "document-setup")
    return (
      <SetupDocumentType
        goBack={() => setStep("select-document-type")}
        onNext={() => setStep("select-document-mode")}
        document_type={documentType}
      />
    );
  if (step === "select-document-mode") {
    return (
      <SelectDocumentMode
        document_type={documentType}
        goBack={() => setStep("document-setup")}
        onNext={(newDocumentMode) => {
          setDocumentMode(newDocumentMode);
          setStep("send-file");
        }}
      />
    );
  }
  if (step === "send-file") {
    if (documentMode === "file") {
      return (
        <FileInstructionsFile
          goBack={() => setStep("select-document-mode")}
          onNext={({ backImage, frontImage, selfieImage }) => {
            const newGuest = {
              ...defaultGuest,
              document_file: frontImage,
              document_file_back: backImage,
              selfie_file: selfieImage,
              match: true,
            };
            onNext(newGuest);
          }}
          document_type={documentType}
        />
      );
    }
    if (documentMode === "photo") {
      return (
        <FileInstructionsPhoto
          goBack={() => setStep("select-document-mode")}
          onNext={({ backImage, frontImage, selfieImage }) => {
            const newGuest = {
              ...defaultGuest,
              document_file: frontImage,
              document_file_back: backImage,
              selfie_file: selfieImage,
              match: true,
            };
            onNext(newGuest);
          }}
          document_type={documentType}
        />
      );
    }
  }

  return <></>;
};
