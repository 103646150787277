import React from "react";

export const NightIcon = ({ size, color, ...props }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      stroke="#8D8D8D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m12.2 1 .433.865c.185.372.278.557.402.718.11.143.239.271.381.382.162.124.347.217.72.403L15 3.8l-.865.432c-.372.186-.557.28-.719.403-.142.11-.27.239-.38.382-.125.16-.218.346-.403.718L12.2 6.6l-.432-.865c-.186-.372-.28-.557-.403-.718a2.098 2.098 0 0 0-.382-.382c-.16-.124-.346-.217-.718-.403L9.4 3.8l.865-.432c.372-.186.557-.28.718-.403.143-.11.271-.239.382-.382.124-.16.217-.346.402-.718L12.2 1ZM14.3 8.973A5.332 5.332 0 1 1 7.028 1.7 6.666 6.666 0 1 0 14.3 8.973Z"
    />
  </svg>
);
