import Button from "../../../../../../components/Buttons/Button";
import { CgArrowLeft } from "react-icons/cg";
import { IReservation } from "../../../../interfaces";
import { inactiveItens } from "../../../../../../theme";
import { BedIcon } from "../../../../../../components/SvgComponents/Icons/BedIcon";
import { GuestIcon } from "../../../../../../components/SvgComponents/Icons/GuestIcon";
import { BathroomIcon } from "../../../../../../components/SvgComponents/Icons/BathroomIcon";
import { ArrowRightIcon } from "../../../../../../components/SvgComponents/Icons/ArrowRightIcon";

interface IAccommodationStepProps {
  reservation: IReservation;
  onNext: () => void;
}

const formatAddress = (address: IReservation["accommodation"]["address"]) => {
  return `${address.street}, ${address.streetNumber}, ${address.region}, ${address.city}, ${address.state}, CEP ${address.zip}`;
};

export const AccommodationStep: React.FC<IAccommodationStepProps> = ({
  reservation,
  onNext,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "0 20px",
        flex: 1,
      }}
    >
      <div
        style={{ display: "flex", flexDirection: "column", gap: 10, flex: 1 }}
      >
        <div
          style={{
            fontWeight: "bold",
            fontSize: 18,
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 5,
          }}
        >
          <div>Acomodação</div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
          <img
            src={reservation.accommodation.main_image}
            style={{
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              objectFit: "cover",
              objectPosition: "center",
            }}
            height={178}
          />
          <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ fontWeight: "bold", fontSize: 16 }}>
                {reservation.accommodation.title}
              </div>
              <div style={{ fontSize: 14, color: "#5B5B5B" }}>
                {formatAddress(reservation.accommodation.address)}
              </div>
            </div>
            <div style={{ borderTop: "1px solid #EFEFEF" }} />
            <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
              <div style={{ display: "flex", gap: 10 }}>
                <BedIcon />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ fontSize: 14, color: "#8D8D8D" }}>
                    Número de camas
                  </div>
                  <div
                    style={{
                      fontSize: 14,
                      color: "#3A3A3A",
                      fontWeight: "bold",
                    }}
                  >
                    {reservation.accommodation.beds} camas
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", gap: 10 }}>
                <GuestIcon />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ fontSize: 14, color: "#8D8D8D" }}>
                    Quantidade máxima de hóspedes
                  </div>
                  <div
                    style={{
                      fontSize: 14,
                      color: "#3A3A3A",
                      fontWeight: "bold",
                    }}
                  >
                    {reservation.accommodation.max_guests} hóspedes
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", gap: 10 }}>
                <BathroomIcon />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ fontSize: 14, color: "#8D8D8D" }}>
                    Número de banheiros
                  </div>
                  <div
                    style={{
                      fontSize: 14,
                      color: "#3A3A3A",
                      fontWeight: "bold",
                    }}
                  >
                    {reservation.accommodation.max_guests} banheiros
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Button
        background={"#6BFEB1"}
        color={"#292929"}
        style={{ marginTop: 30 }}
        onClick={onNext}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flex: 1,
            padding: "0px 10px",
          }}
        >
          Detalhes da Reserva
          <ArrowRightIcon />
        </div>
      </Button>
    </div>
  );
};
