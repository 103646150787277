import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
} from "react";
import { Masks } from "./mask";
import { MaskTypes } from "../../../interfaces";
import { useGlobal } from "../../../hooks/global";
import { BaseInput, ContainerIconSeePassword } from "./style";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { ContainerField, FieldError, Line } from "../../../style";
import Form from "react-bootstrap/Form";

export interface InputRefProps {
  getValue: () => string;
  setValue: (newValue: string) => void;
  focus: () => void;
}

interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  mask?: MaskTypes;
  noUpperCase?: boolean;
  isPassword?: boolean;
  error?: any;
  options?: any;
  label?: string;
}

const Input: React.ForwardRefRenderFunction<InputRefProps, InputProps> = (
  props,
  ref
) => {
  const {
    mask,
    error,
    options = { onChange: () => {} },
    style = {},
    isPassword = false,
    noUpperCase = false,
    onKeyDown = () => {},
    onChange = () => {},
    label = "Label",
    ...rest
  } = props;

  const { theme } = useGlobal();

  const inputRef = useRef<HTMLInputElement>(null);

  const [seePassword, setSeePassword] = React.useState(false);

  const handleKeyUp = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      if (mask && Masks?.[mask as string]) Masks[mask](e);
    },
    [mask]
  );

  const getValue = useCallback(() => {
    if (mask === "m2") {
      let value_input: any = inputRef.current?.value
        ? inputRef.current?.value
        : "";
      value_input = value_input.replace(/\D/g, "");
      if (value_input !== "") value_input = Number.parseInt(value_input) / 100;
      return value_input;
    } else return inputRef.current?.value ? inputRef.current?.value : "";
  }, [inputRef]);

  const focus = useCallback(() => {
    inputRef.current?.focus();
  }, [inputRef]);

  const setValue = useCallback(
    (newValue: string) => {
      if (inputRef && inputRef.current) inputRef.current.value = newValue;
    },
    [inputRef]
  );

  const handleKeyDown = useCallback(
    (e: any) => {
      if (
        mask === "m2" &&
        (e.nativeEvent.key === "Delete" ||
          e.nativeEvent.key === "Backspace" ||
          (e.charCode >= 48 && e.charCode <= 57))
      ) {
        let value = e.currentTarget.value;
        value = value.replace(" m²", "");
        e.currentTarget.value = value;
      }
      if (
        mask === "porcen" &&
        (e.nativeEvent.key === "Delete" ||
          e.nativeEvent.key === "Backspace" ||
          (e.charCode >= 48 && e.charCode <= 57))
      ) {
        let value = e.currentTarget.value;
        value = value.replace(" %", "");
        e.currentTarget.value = value;
      }
      onKeyDown(e);
      return e;
    },
    [mask]
  );

  const handleOnChange = useCallback(
    (e: any) => {
      onChange(e?.target?.value);
      options.onChange(e);
    },
    [mask]
  );

  useImperativeHandle(ref, () => ({ getValue, setValue, focus }));

  return (
    <ContainerField style={{ flex: 1, marginTop: 1 }}>
      <BaseInput label={label} theme={theme} haveError={!!error}>
        <Form.Control
          onKeyDown={handleKeyDown}
          style={{
            ...style,
            textTransform: noUpperCase ? "none" : "uppercase",
            paddingRight: isPassword ? 40 : undefined,
          }}
          ref={inputRef}
          autoComplete="nope"
          onKeyUp={handleKeyUp}
          type={isPassword ? (seePassword ? "text" : "password") : "text"}
          {...rest}
          {...options}
          onChange={handleOnChange}
        />
        {isPassword && (
          <ContainerIconSeePassword
            onClick={() => setSeePassword((old) => !old)}
          >
            {!seePassword && (
              <IoEyeOutline size={20} color={"rgb(108 108 108)"} />
            )}
            {seePassword && (
              <IoEyeOffOutline size={20} color={"rgb(108 108 108)"} />
            )}
          </ContainerIconSeePassword>
        )}
        {error && !props.disabled && (
          <FieldError title={error}>{error}</FieldError>
        )}
      </BaseInput>
    </ContainerField>
  );
};

export default forwardRef(Input);
