import * as React from "react";
import { primary } from "../../../../theme";

export const LicenseIcon = ({ size, color, ...props }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={22}
    fill="none"
    {...props}
  >
    <path
      stroke="#AEAEAE"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M15.4 6h4.8m-4.8 5h4.8m-4.8 5h4.8M4.84 21h16.32c1.344 0 2.016 0 2.53-.273.451-.24.818-.622 1.048-1.092C25 19.1 25 18.4 25 17V5c0-1.4 0-2.1-.262-2.635a2.452 2.452 0 0 0-1.048-1.093C23.176 1 22.504 1 21.16 1H4.84c-1.344 0-2.016 0-2.53.272-.451.24-.818.623-1.048 1.093C1 2.9 1 3.6 1 5v12c0 1.4 0 2.1.262 2.635.23.47.597.853 1.048 1.092C2.824 21 3.496 21 4.84 21ZM6.4 11H10c.331 0 .6-.28.6-.625v-3.75A.613.613 0 0 0 10 6H6.4c-.331 0-.6.28-.6.625v3.75c0 .345.269.625.6.625Z"
    />
  </svg>
);
